import { Divider, Stack } from "@mui/material";
import { useNavigate } from "react-router";
import ChatWithMedicalProfessional from ".";
import theme from "../../theme";

export default function ChatWithMedicalProfessionalExamples() {
  const navigate = useNavigate();
  function onContinue() {
    console.log("On continue clicked");
  }

  function onBackButtonClick() {
    console.log("On back button clicked");
    navigate(-1);
  }

  return (
    <>
      <Stack>
        <ChatWithMedicalProfessional
          type="NURSE"
          amountDue="R80.00"
          onBackButtonClick={onBackButtonClick}
          onContinue={onContinue}
        />
        <Divider
          sx={{
            backgroundColor: theme.palette.neutral[50],
            py: 2,
          }}
        />
        <ChatWithMedicalProfessional
          type="DOCTOR"
          amountDue="R120.00"
          onBackButtonClick={onBackButtonClick}
          onContinue={onContinue}
        />
      </Stack>
    </>
  );
}
