import CollectMarketingCommunicationPreferences from ".";

export default function CollectMarketingCommunicationPreferencesExamples() {
  function onSubmit(payload: any) {
    console.log(payload);
  }

  return (
    <>
      <CollectMarketingCommunicationPreferences
        phoneNumber="0123456789"
        onSubmit={onSubmit}
      />
    </>
  );
}
