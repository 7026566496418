import { setup, assign, fromPromise } from "xstate";
import {
  questionnaireList,
  questionnaireSummary,
  QuestionnaireSummarySuccessResponseData,
  questionnaireCurrent,
  QuestionnaireCurrentSuccessResponseData,
  questionnaireSubmitAnswer,
  questionnaireCompleteLater,
  questionnaireSkipQuestion,
  QuestionnaireListSuccessResponseData,
} from "../../services/core-api-adapter";

export const eventNames = {
  GO_BACK: "GO_BACK",
  SKIP_QUESTIONNAIRE_BUTTON_CLICKED: "SKIP_QUESTIONNAIRE_BUTTON_CLICKED",
  COMPLETE_LATER_BUTTON_CLICKED: "COMPLETE_LATER_BUTTON_CLICKED",
  GET_STARTED_BUTTON_CLICKED: "GET_STARTED_BUTTON_CLICKED",
  SKIP_QUESTION_BUTTON_CLICKED: "SKIP_QUESTION_BUTTON_CLICKED",
  ANSWER_IS_VALID: "ANSWER_IS_VALID",
  ANSWER_IS_INVALID: "ANSWER_IS_INVALID",
  ANSWER_COLLECTED: "ANSWER_COLLECTED",
  ANSWER_QUESTION_BUTTON_CLICKED: "ANSWER_QUESTION_BUTTON_CLICKED",
  UNSUPPORTED_QUESTION_TYPE: "UNSUPPORTED_QUESTION_TYPE",
};

interface Context {
  isBackTransitionAllowed: boolean;
  isFlowHeaderVisible: boolean;
  isCompleteLaterButtonVisible: boolean;
  questionnaireListResponse: Array<QuestionnaireListSuccessResponseData>;
  chosenQuestionnaireID: string;
  chosenQuestionnaireSummary: QuestionnaireSummarySuccessResponseData;
  isAnswerQuestionSubmitEnabled: boolean;
  currentQuestion: QuestionnaireCurrentSuccessResponseData;
  answerPayload: any;
}

const initialContextValues: Context = {
  isBackTransitionAllowed: false,
  isFlowHeaderVisible: false,
  isCompleteLaterButtonVisible: false,
  questionnaireListResponse: [],
  chosenQuestionnaireID: "",
  isAnswerQuestionSubmitEnabled: false,
  chosenQuestionnaireSummary: {
    name: "",
    type: "",
    version: 0,
    totalQuestions: 1,
    totalAnswered: 0,
    currentQuestionIndex: 1,
    status: "",
  },
  currentQuestion: {
    id: "",
    name: "",
    description: "",
    questionType: "",
    questionOptions: null,
  },
  answerPayload: null,
};

export const questionnaireFlowStateMachine = setup({
  types: {
    context: {} as Context,
    events: {} as any,
  },
  actors: {
    questionnaireList: fromPromise(async () => {
      return await questionnaireList();
    }),
    questionnaireCompleteLater: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const { context } = input;
        return await questionnaireCompleteLater(context.chosenQuestionnaireID);
      }
    ),
    questionnaireSummary: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const { context } = input;
        return await questionnaireSummary(context.chosenQuestionnaireID);
      }
    ),
    questionnaireCurrent: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const { context } = input;
        return await questionnaireCurrent(context.chosenQuestionnaireID);
      }
    ),
    questionnaireSubmitAnswer: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const { context } = input;
        return await questionnaireSubmitAnswer(
          context.chosenQuestionnaireID,
          context.currentQuestion.id,
          context.answerPayload
        );
      }
    ),
    skippingQuestion: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const { context } = input;
        return await questionnaireSkipQuestion(
          context.chosenQuestionnaireID,
          context.currentQuestion.id
        );
      }
    ),
  },
  guards: {
    hasAtLeastOneQuestionnaireToComplete: function ({ context }) {
      return context.questionnaireListResponse.length > 0;
    },
    hasNoQuestionnairesToAnswer: function ({ context }) {
      return context.questionnaireListResponse.length === 0;
    },
  },
}).createMachine({
  context: initialContextValues,
  id: "questionnaireFlow",
  initial: "start",
  states: {
    start: {
      entry: assign({
        ...initialContextValues,
      }),
      invoke: {
        id: "questionnaireList",
        src: "questionnaireList",
        input: {},
        onDone: {
          target: "promptingOrExit",
          actions: assign({
            questionnaireListResponse: ({ event }) => {
              return Array.isArray(event.output) ? event.output : [];
            },
          }),
        },
        onError: {
          target: "exit",
        },
      },
    },
    promptingOrExit: {
      always: [
        {
          target: "prompting",
          guard: {
            type: "hasAtLeastOneQuestionnaireToComplete",
          },
        },
        {
          target: "exit",
          guard: {
            type: "hasNoQuestionnairesToAnswer",
          },
        },
      ],
    },

    exit: {
      type: "final",
    },

    prompting: {
      on: {
        GO_BACK: {
          target: "exit",
        },
        COMPLETE_LATER_BUTTON_CLICKED: {
          target: "completeLater",
        },
        GET_STARTED_BUTTON_CLICKED: {
          target: "fetchingQuestionnaireSummary",
        },
      },
      entry: assign({
        isFlowHeaderVisible: false,
        chosenQuestionnaireID: ({ context }) =>
          context.questionnaireListResponse[0].id,
        isCompleteLaterButtonVisible: false,
      }),
    },

    completeLater: {
      entry: assign({
        isFlowHeaderVisible: false,
        isCompleteLaterButtonVisible: false,
      }),
      invoke: {
        id: "questionnaireCompleteLater",
        src: "questionnaireCompleteLater",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "exit",
        },
        onError: {
          target: "exit",
        },
      },
    },

    fetchingQuestionnaireSummary: {
      entry: assign({
        isFlowHeaderVisible: false,
        isCompleteLaterButtonVisible: false,
      }),
      invoke: {
        id: "questionnaireSummary",
        src: "questionnaireSummary",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "fetchingCurrentQuestion",
          actions: assign({
            chosenQuestionnaireSummary: (_: any, event: any) => {
              return event.data;
            },
          }),
        },
        onError: {
          target: "done",
        },
      },
    },

    fetchingCurrentQuestion: {
      entry: assign({
        isFlowHeaderVisible: false,
        isCompleteLaterButtonVisible: false,
      }),
      invoke: {
        id: "questionnaireCurrent",
        src: "questionnaireCurrent",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "collectingAnswer",
          actions: assign({
            currentQuestion: (_: any, event: any) => {
              return event.data;
            },
          }),
        },
        onError: {
          target: "done",
        },
      },
    },

    done: {
      type: "final",
      entry: assign({
        isFlowHeaderVisible: false,
        isCompleteLaterButtonVisible: false,
      }),
    },

    collectingAnswer: {
      on: {
        COMPLETE_LATER_BUTTON_CLICKED: {
          target: "completeLater",
        },
        ANSWER_IS_VALID: {
          actions: assign(() => {
            return {
              isAnswerQuestionSubmitEnabled: true,
            };
          }),
        },
        ANSWER_IS_INVALID: {
          actions: assign(() => {
            return {
              isAnswerQuestionSubmitEnabled: false,
            };
          }),
        },
        ANSWER_COLLECTED: {
          actions: assign(({ event }) => {
            return {
              answerPayload: event.info,
            };
          }),
        },
        SKIP_QUESTION_BUTTON_CLICKED: {
          target: "skippingQuestion",
        },
        ANSWER_QUESTION_BUTTON_CLICKED: {
          target: "submittingAnswerToQuestion",
        },
        UNSUPPORTED_QUESTION_TYPE: {
          target: "done",
        },
      },
      entry: assign({
        answerPayload: null,
        isFlowHeaderVisible: true,
        isCompleteLaterButtonVisible: true,
        isAnswerQuestionSubmitEnabled: false,
      }),
    },

    skippingQuestion: {
      entry: assign({
        isFlowHeaderVisible: false,
        isCompleteLaterButtonVisible: false,
      }),
      invoke: {
        id: "skippingQuestion",
        src: "skippingQuestion",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "fetchingQuestionnaireSummary",
        },
        onError: {
          target: "done",
        },
      },
    },

    submittingAnswerToQuestion: {
      invoke: {
        id: "questionnaireSubmitAnswer",
        src: "questionnaireSubmitAnswer",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "fetchingQuestionnaireSummary",
        },
        onError: {
          target: "done",
        },
      },
    },
  },
});
