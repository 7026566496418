import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog from "../DefaultDialog";
import WatermarkIcon from "../WatermarkIcon";
import theme from "../../theme";

interface OutsideConsultationOperatingHoursModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default function OutsideConsultationOperatingHoursModal({
  isOpen,
  onClose,
  onSubmit,
}: OutsideConsultationOperatingHoursModalProps) {
  const { t } = useTranslation();
  return (
    <DefaultDialog
      label={t("OutsideConsultationOperatingHoursModal.title")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <WatermarkIcon
        extra={{
          alignSelf: "center",
        }}
        height={55}
        width={55}
        iconSize="medium"
        color={theme.palette.accent1[700]}
        foreground={theme.palette.neutral[50]}
        Icon={"CheckmarkIcon"}
      />

      <Typography variant="h3" fontWeight={600} align="center">
        {t("OutsideConsultationOperatingHoursModal.title")}
      </Typography>

      {(
        t<any, any, string[]>(
          `OutsideConsultationOperatingHoursModal.content`,
          {
            returnObjects: true,
          }
        ) as []
      ).map((text: string) => (
        <Typography key={text} textAlign="center">
          {text}
        </Typography>
      ))}

      <Button size="small" onClick={onSubmit}>
        {t("common.close")}
      </Button>
    </DefaultDialog>
  );
}
