import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";
import { MembershipStatus } from "../../services/core-api-adapter";

type Props = DefaultDialogImplementationProps & { status: MembershipStatus };

export default function MembershipDependentsStatusModal({
  isOpen,
  onClose,
  status,
}: Props) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(`MembershipDependentsStatusModal.${status}.title` as any)}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      {(
        t<any, any, string[]>(
          `MembershipDependentsStatusModal.${status}.body`,
          {
            returnObjects: true,
          }
        ) as []
      ).map((text: string, index: number) => (
        <Typography textAlign="center" key={status + String(index)}>
          {text}
        </Typography>
      ))}
    </DefaultDialog>
  );
}
