import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthIdentificationTypeOption } from "../..";
import { FaceIconBrandLarge } from "../../../../theme/icons";
import { convertPxToRem } from "../../../../utils";
import IconLoader from "../../../IconLoader";
import { GetHelpFABConfig } from "../../../../theme";
import GetHelpCTAButton from "../../../GetHelpCTAButton";

interface AuthIdentificationTypeSelectionV2Props {
  authIdentificationTypes: AuthIdentificationTypeOption[];
  selectedAuthIdentificationType: AuthIdentificationTypeOption | null;
  setSelectedAuthIdentificationType: (
    authIdentificationType: AuthIdentificationTypeOption
  ) => void;
  onContinueButtonClick: () => void;
  onUNUIDButtonClick: () => void;
}

export default function AuthIdentificationTypeSelectionV2({
  authIdentificationTypes,
  selectedAuthIdentificationType,
  setSelectedAuthIdentificationType,
  onContinueButtonClick,
  onUNUIDButtonClick,
}: AuthIdentificationTypeSelectionV2Props) {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          p: 2,
          minHeight: "100%",
        }}
      >
        <Stack flexDirection="row" justifyContent="space-between">
          <IconLoader
            icon="UnuLogo"
            color="primary"
            sx={{
              fontSize: convertPxToRem(90),
            }}
          />
        </Stack>

        <Stack>
          <Stack alignItems="center" spacing={2} pb={2}>
            <FaceIconBrandLarge />
            <Typography variant="h2" component="h1" textAlign="center">
              {t("AuthIdentificationTypeSelectionV2.title")}
            </Typography>
            <Typography variant="body1" color="neutral.main" textAlign="center">
              {t("AuthIdentificationTypeSelectionV2.subtitle")}
            </Typography>
          </Stack>

          <Stack spacing={1} pb={2}>
            <Stack spacing={2} p={2} flexDirection="row">
              <ToggleButtonGroup
                color="neutral"
                value={selectedAuthIdentificationType?.identityDocumentSubType}
                onChange={(event, value) => {
                  const selectedType = authIdentificationTypes.find(
                    (type) => type.identityDocumentSubType === value
                  );
                  if (selectedType) {
                    setSelectedAuthIdentificationType({
                      id: selectedType.id,
                      label: selectedType.label,
                      icon: selectedType.icon,
                      identityDocumentSubType:
                        selectedType.identityDocumentSubType,
                    });
                  }
                }}
                fullWidth
                exclusive
              >
                {authIdentificationTypes.map(
                  (authIdentificationType: AuthIdentificationTypeOption) => {
                    if (authIdentificationType.id === "UNU_ID") {
                      return null;
                    }
                    return (
                      <ToggleButton
                        key={
                          authIdentificationType.label +
                          authIdentificationType.identityDocumentSubType
                        }
                        value={
                          authIdentificationType.identityDocumentSubType as any
                        }
                      >
                        {authIdentificationType.label}
                      </ToggleButton>
                    );
                  }
                )}
              </ToggleButtonGroup>
            </Stack>
            <Stack textAlign="center">
              {authIdentificationTypes.map(
                (authIdentificationType: AuthIdentificationTypeOption) => {
                  if (authIdentificationType.id !== "UNU_ID") {
                    return null;
                  }
                  return (
                    <Button
                      key={authIdentificationType.id}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        onUNUIDButtonClick();
                      }}
                    >
                      {t("AuthIdentificationTypeSelection.UnuId")}
                    </Button>
                  );
                }
              )}
            </Stack>
          </Stack>

          <Stack spacing={1} alignItems="center" pb={2}>
            <IconLoader icon="PadlockIcon" />
            <Typography textAlign="center" variant="body2">
              {t("AuthIdentificationTypeSelectionV2.footer")}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignSelf="flex-end"
            position="relative"
            width={GetHelpFABConfig.floatingActionButtonWidth}
          >
            <GetHelpCTAButton eventName="action.contactSupportFab" />
          </Stack>
          <Button
            disabled={!selectedAuthIdentificationType}
            onClick={onContinueButtonClick}
          >
            {t("common.nextButton")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
