import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  ListItem,
} from "@mui/material";
import {
  IdentityDocumentType,
  Member,
  MembershipStatus,
  MembershipType,
} from "../../services/core-api-adapter";
import FaceIconBrand from "../../theme/icons/FaceIconBrand";
import FilledStarIcon from "../../theme/icons/FilledStarIcon";
import MembershipDependentsStatusModal from "../MembershipDependentsStatusModal";
import MembershipStatusIndicator from "../MembershipStatusIndicator";
import useGetMemberships from "../../hooks/useGetMemberships";
import theme from "../../theme";
import { replaceSubjectWithString } from "../../utils";

interface MembershipDetailDependentsListProps {
  dependents: Member[];
}

export default function MembershipDetailDependentsList({
  dependents,
}: MembershipDetailDependentsListProps) {
  const { t } = useTranslation();
  const { firstMembership } = useGetMemberships();
  const [
    isMembershipDependentsStatusModalOpen,
    setIsMembershipDependentsStatusModalOpen,
  ] = useState(false);

  function onMembershipDependentStatusClick() {
    setIsMembershipDependentsStatusModalOpen(true);
  }

  return (
    <>
      <Stack spacing={1}>
        <MembershipDependentsStatusModal
          isOpen={isMembershipDependentsStatusModalOpen}
          onClose={() => setIsMembershipDependentsStatusModalOpen(false)}
          status={MembershipStatus.PENDING}
        />
        <Typography variant="h3" color="GrayText">
          {t("MembershipDependentsDetailPanel.dependentsList.label")}
        </Typography>

        <Stack
          component="ul"
          divider={<Divider />}
          aria-label={t("MembershipDependentsDetailPanel.dependentsList.label")}
        >
          {dependents.map((dependent, index) => {
            const fullName = dependent.memberDetails.memberFullName;
            const identityDocumentValue =
              dependent?.memberDetails?.identityDocumentValue || "";
            const key = `${fullName}_${index}`;
            const isMainMember =
              dependent.membershipDetails.type === MembershipType.MAIN_MEMBER;
            const identityDocumentType = dependent.memberDetails
              .identityDocumentType
              ? replaceSubjectWithString(
                  dependent.memberDetails.identityDocumentType,
                  IdentityDocumentType.UNUID,
                  "UNUID"
                )
              : null;

            return (
              <ListItem key={key} aria-label={fullName} sx={{ px: 0, py: 2 }}>
                <Stack spacing={1} width="100%">
                  <Stack spacing={2} direction="row" width="100%">
                    <Stack
                      sx={{ position: "relative", height: theme.spacing(4) }}
                    >
                      <FaceIconBrand
                        sx={{ fontSize: (theme) => theme.spacing(4) }}
                      />
                      {isMainMember && (
                        <Stack
                          sx={{
                            position: "absolute",
                            bottom: (theme) => theme.spacing(-0.5),
                            right: (theme) => theme.spacing(-0.5),
                          }}
                        >
                          <FilledStarIcon
                            sx={{ fontSize: (theme) => theme.spacing(2) }}
                          />
                        </Stack>
                      )}
                    </Stack>
                    <Stack spacing={1} width="100%">
                      <Stack
                        spacing={1}
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Stack>
                          <Typography
                            component="p"
                            variant="h4"
                            fontWeight="600"
                          >
                            {fullName}
                          </Typography>
                          {isMainMember && (
                            <Typography
                              component="p"
                              variant="h5"
                              fontWeight="600"
                            >
                              {t(
                                "MarketplaceProductDetail.MemberType.MAIN_MEMBER.title"
                              )}
                            </Typography>
                          )}
                        </Stack>
                        <Stack>
                          <MembershipStatusIndicator
                            status={dependent.membershipDetails.status}
                          />
                        </Stack>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Box>
                          {identityDocumentType && (
                            <>
                              <Typography variant="body2" color="GrayText">
                                {t(
                                  `MembershipDependentsDetailPanel.dependentsList.item.${identityDocumentType}Label` as any
                                )}
                              </Typography>
                              <Typography>{identityDocumentValue}</Typography>
                            </>
                          )}
                        </Box>

                        {dependent.membershipDetails.beneficiaryCode && (
                          <Box>
                            <Typography variant="body2" color="GrayText">
                              {t(
                                "MembershipDependentsDetailPanel.dependentsList.item.dependentCodeLabel"
                              )}
                            </Typography>
                            <Typography textAlign="right">
                              {dependent.membershipDetails.beneficiaryCode}
                            </Typography>
                          </Box>
                        )}
                      </Stack>

                      <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Stack>
                          <Typography variant="body2" color="GrayText">
                            {t("MembershipDetailPanel.dateOfBirthLabel")}
                          </Typography>
                          <Typography variant="body2">
                            {dependent?.memberDetails?.dateOfBirth
                              ? new Date(
                                  dependent.memberDetails.dateOfBirth
                                ).toLocaleDateString()
                              : null}
                          </Typography>
                        </Stack>

                        {firstMembership.productDetails.startDate && (
                          <Stack>
                            <Typography
                              variant="body2"
                              color="GrayText"
                              textAlign="right"
                            >
                              {t("MembershipDetailPanel.activationDateLabel")}
                            </Typography>
                            <Typography variant="body2" textAlign="right">
                              {new Date(
                                firstMembership.productDetails.startDate
                              ).toLocaleDateString()}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>

                  {dependent.membershipDetails.status === "PENDING" && (
                    <Button
                      variant="text"
                      size="small"
                      fullWidth
                      onClick={onMembershipDependentStatusClick}
                    >
                      {t(
                        "MembershipDependentsDetailPanel.dependentsList.item.action.membershipPendingButton"
                      )}
                    </Button>
                  )}
                </Stack>
              </ListItem>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
}
