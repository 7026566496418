import { Stack } from "@mui/material";
import AvailableHealthChecks from ".";
import { ServiceOfferingUsageEnum } from "../../services/core-api-adapter";

export default function AvailableHealthChecksExamples() {
  return (
    <Stack
      spacing={2}
      p={2}
      justifyContent="space-between"
      sx={{ height: "100%", overflowX: "hidden" }}
    >
      <AvailableHealthChecks
        serviceOfferingUsageList={[
          {
            availabilityStatus: ServiceOfferingUsageEnum.USED,
            availableDateRange: {
              startDate: "2024-01-16T13:42:36.521883+00:00",
              endDate: "2024-01-16T13:42:36.521883+00:00",
            },
            scanCompletionDate: "01/01/2024",
            order: 1,
            unlimited: false,
          },
          {
            availabilityStatus: ServiceOfferingUsageEnum.EXPIRED,
            availableDateRange: {
              startDate: "2024-01-16T13:42:36.521883+00:00",
              endDate: "2024-02-16T13:42:36.521883+00:00",
            },
            scanCompletionDate: null,
            order: 2,
            unlimited: false,
          },
        ]}
        availableServiceOfferingUsageCount={2}
        needsMedicalAssistance={false}
      />

      <AvailableHealthChecks
        serviceOfferingUsageList={[
          {
            availabilityStatus: ServiceOfferingUsageEnum.USED,
            availableDateRange: {
              startDate: "2024-01-16T13:42:36.521883+00:00",
              endDate: "2024-01-16T13:42:36.521883+00:00",
            },
            scanCompletionDate: "01/01/2024",
            order: 1,
            unlimited: false,
          },
          {
            availabilityStatus: ServiceOfferingUsageEnum.EXPIRED,
            availableDateRange: {
              startDate: "2024-01-16T13:42:36.521883+00:00",
              endDate: "2024-02-16T13:42:36.521883+00:00",
            },
            scanCompletionDate: null,
            order: 2,
            unlimited: false,
          },
        ]}
        availableServiceOfferingUsageCount={2}
        needsMedicalAssistance={true}
      />
    </Stack>
  );
}
