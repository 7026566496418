import { useState, useEffect } from "react";
import { Navigate } from "react-router";
import { trackEvent } from "../../services/analytics-adapter";
import { getMembershipConfig } from "../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import useGetMemberships from "../../hooks/useGetMemberships";
import { Default } from "./components/Default";

interface MedicalAssistanceProps {
  showInformationPrompt?: boolean;
}

export function MedicalAssistance({
  showInformationPrompt = false,
}: MedicalAssistanceProps) {
  const { isMembershipsLoading, firstMembership, memberships } =
    useGetMemberships();

  const [isGetMedicalAssistanceSupported, setIsGetMedicalAssistanceSupported] =
    useState<boolean | undefined>(undefined);
  const [isIntercareSupported, setIsIntercareSupported] = useState<
    boolean | undefined
  >(undefined);
  const [isDefaultMedicalAssistance, setIsDefaultMedicalAssistance] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    if (firstMembership && !isMembershipsLoading) {
      const shouldUseIntercareAssistance =
        getMembershipConfig(firstMembership)?.isIntercareSupported &&
        isFeatureEnabled(
          FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
        );

      setIsGetMedicalAssistanceSupported(
        getMembershipConfig(firstMembership)
          ?.isGetMedicalAssistanceSupported === true ||
          shouldUseIntercareAssistance === true
      );

      setIsIntercareSupported(shouldUseIntercareAssistance);
      setIsDefaultMedicalAssistance(!shouldUseIntercareAssistance);
    }

    const medicalServiceProvider =
      firstMembership?.productDetails.medicalServiceProvider;

    trackEvent({
      event: "action.getMedicalAssistanceTypeSelected",
      "getMedicalAssistance.type": "chat with a nurse",
      ...(medicalServiceProvider && {
        "getMedicalAssistance.source": medicalServiceProvider,
      }),
    });
  }, [memberships]);

  return (
    <>
      {isGetMedicalAssistanceSupported === false && (
        <Navigate to="/home/get-medical-assistance/unsupported" replace />
      )}

      {isGetMedicalAssistanceSupported === true && (
        <>
          {isIntercareSupported &&
            isFeatureEnabled(
              FeatureToggleIdentifier.ENABLE_INTERCARE_MEDICAL_ASSISTANCE
            ) && (
              <Navigate to="/home/get-medical-assistance/intercare" replace />
            )}

          {isDefaultMedicalAssistance && (
            <Default showInformationPrompt={showInformationPrompt} />
          )}
        </>
      )}
    </>
  );
}
