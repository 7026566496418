import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { convertPxToRem } from "../../utils";
import FullscreenBackground from "../FullscreenBackground";
import IconLoader from "../IconLoader";
import { trackEvent } from "../../services/analytics-adapter";

interface FaceScanBreakoutProps {
  provider: string;
  onBack: () => void;
  onContinue?: () => void;
}

function providerNormalised(name: string) {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}

export default function FaceScanBreakout({
  provider,
  onBack,
  onContinue,
}: FaceScanBreakoutProps) {
  const { t } = useTranslation();

  function onBackClickHander() {
    trackEvent({
      event: "action.healthCheckFaceScanSkipped",
      source: "health check",
    });
    onBack();
  }

  return (
    <>
      <Stack
        justifyContent="flex-end"
        color="primary.contrastText"
        spacing={6}
        p={2}
        sx={{
          height: "100%",
        }}
      >
        <IconLoader
          icon="ChatWithMedicalProfessionalIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />

        <Stack textAlign="center" spacing={4} py={4}>
          <Stack>
            <Typography variant="h1" color="inherit">
              {t("FaceScanBreakout.title")}
            </Typography>

            <Typography variant="body2" color="inherit">
              {t("FaceScanBreakout.subtitle", {
                provider: providerNormalised(provider),
              })}
            </Typography>
          </Stack>

          <Typography variant="h3" color="inherit">
            {t("FaceScanBreakout.body", {
              provider: providerNormalised(provider),
            })}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          gap={2}
          alignItems="center"
        >
          <IconLoader
            icon="FaceIconBackground"
            color="info"
            sx={{
              fontSize: convertPxToRem(38),
            }}
          />
          <IconLoader icon="ArrowNextIcon" color="info" />
          <Box
            src={`/assets/MedicalAssistancePartners/${providerNormalised(provider)}/logo.svg`}
            component="img"
            alt={providerNormalised(provider)}
          />
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            onClick={onBackClickHander}
            color="info"
            variant="outlined"
            fullWidth
          >
            {t("common.backButton")}
          </Button>
          <Button onClick={onContinue} color="info" fullWidth>
            {t("common.continueButton")}
          </Button>
        </Stack>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </>
  );
}
