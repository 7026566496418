import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box, Button, Stack, Typography } from "@mui/material";
import { logout } from "../../services/core-api-adapter";
import { useGlobalStore } from "../../store";
import DefaultDialog from "../DefaultDialog";
import CircledBackButton from "../CircledBackButton";
import ProcessIndicatorIcon from "../ProcessIndicatorIcon";

interface RejectTermsOrConsentDialogProps {
  isOpen: boolean;
  onClose: Function;
}

export default function RejectTermsOrConsentDialog(
  props: RejectTermsOrConsentDialogProps
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const onClose = () => {
    setIsOpen(false);
    props.onClose();
  };

  const onEndSignUpButtonClick = () => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
    });
    navigate("/welcome");
  };

  return (
    <DefaultDialog
      isFullscreen={true}
      fullWidth={true}
      label={t("RejectTermsOrConsentDialog.title")}
      isOpen={isOpen}
      onClose={onClose}
      animate={true}
      closeButton={false}
      paperStyleProps={{
        borderRadius: 0,
        p: 0,
      }}
      isConstrainedToMaxWidthContainer={true}
    >
      <Stack
        spacing={1}
        justifyContent="space-between"
        sx={{ minHeight: "100%", p: 2 }}
      >
        <CircledBackButton
          onClick={onClose}
          showLabel={true}
          label={t("common.close")}
        />
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Box sx={{ margin: "0 auto", opacity: 0.5, p: 5 }}>
              <ProcessIndicatorIcon type="error" height={88} width={88} />
            </Box>
            <Typography variant="h2">
              {t("RejectTermsOrConsentDialog.title")}
            </Typography>
            {(
              t<any, any, string[]>("RejectTermsOrConsentDialog.body", {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography key={String(text + index)}>{text}</Typography>
            ))}
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={onEndSignUpButtonClick}
              fullWidth
            >
              {t("RejectTermsOrConsentDialog.endSignUpButton")}
            </Button>
            <Button onClick={onClose} fullWidth>
              {t("RejectTermsOrConsentDialog.goBackButton")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </DefaultDialog>
  );
}
