import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import MedicalConditionList, {
  QuestionStatusType,
} from "../MedicalConditionList";

interface MedicationItem {
  name: string;
  imageURL: string;
}

interface MedicalCondition {
  value: string;
  medication: MedicationItem[];
}

interface QuestionTypeMedicalConditionListProps {
  onChange: Function;
  type: QuestionStatusType;
  data?: any[];
}

export default function QuestionTypeMedicalConditionList({
  onChange,
  type,
  data,
}: QuestionTypeMedicalConditionListProps) {
  const { t } = useTranslation();

  function isValid(valuePayload: MedicalCondition[]) {
    return valuePayload && valuePayload.length > 0 ? true : false;
  }

  function onListChange(valuePayload: MedicalCondition[]) {
    if (Array.isArray(valuePayload)) {
      const valuePayloadTransformedForContainer = valuePayload.map(
        (medicalCondition: MedicalCondition) => {
          return {
            value: medicalCondition.value,
            medication: medicalCondition.medication,
          };
        }
      );

      onChange({
        data: valuePayloadTransformedForContainer,
        isValid: isValid(valuePayload),
      });
    }
  }

  return (
    <>
      <Stack spacing={2} justifyContent="space-between">
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography variant="h3">
              {t(`QuestionType${type}List.title`)}
            </Typography>

            {type === QuestionStatusType.ALLERGIES && (
              <Typography color={(theme) => theme.palette.grey["500"]}>
                {t(`QuestionType${type}List.subtitle`)}
              </Typography>
            )}

            <MedicalConditionList
              type={type}
              value={data}
              onChange={onListChange}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
