import { useEffect } from "react";
import WelcomeFlow from "../../components/WelcomeFlow";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function WelcomePage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Home:Welcome",
      pageSubSection1: "Home",
      pageSubSection2: "Home:Welcome",
      pageCategory: "Home",
    });
  }, []);

  return <WelcomeFlow />;
}
