import { useNavigate } from "react-router";
import { Stack, Typography } from "@mui/material";
import CircledBackButton from "../../components/CircledBackButton";

import { membershipManagementPageStyling } from "../../theme";
import { PropsWithChildren } from "react";

interface MembershipPanelWrapperProps {
  goBackButtonText: string;
  goBackButtonVisibleText: string;
  title?: string;
}

export default function MembershipPanelWrapper({
  ...props
}: PropsWithChildren<MembershipPanelWrapperProps>) {
  const navigate = useNavigate();

  return (
    <Stack spacing={2} sx={{ ...membershipManagementPageStyling }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <CircledBackButton
          onClick={() => navigate(-1)}
          label={props.goBackButtonText}
        />
        <Typography variant="h4">{props.goBackButtonVisibleText}</Typography>
      </Stack>

      {props.title && (
        <Typography component="h1" variant="h2">
          {props.title}
        </Typography>
      )}

      {props.children}
    </Stack>
  );
}
