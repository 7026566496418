import InformationBox from ".";
import { Stack, Typography } from "@mui/material";
import theme from "../../theme";

export default function InformationBoxExamples() {
  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h4">
          Information box with default button
        </Typography>
        <InformationBox>
          <Typography variant="h5">
            "The more detail you provide us with, the better we can help you"
          </Typography>
        </InformationBox>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h4">Information box with custom Icon</Typography>
        <InformationBox Icon={"PadlockIcon"}>
          <Typography variant="h5">
            "The more detail you provide us with, the better we can help you"
          </Typography>
        </InformationBox>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h4">Information box with custom text</Typography>
        <InformationBox>
          <Typography variant="h5">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum
            possimus quia dolores minima ratione in cum deserunt? Vel voluptas
            adipisci consequatur veritatis aspernatur cum, beatae
            necessitatibus. Sapiente illum consequuntur quasi!
          </Typography>
        </InformationBox>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h4">
          Information box with custom background color
        </Typography>
        <InformationBox background={theme.palette.primary[500]}>
          <Typography variant="h5">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum
            possimus quia dolores minima ratione in cum deserunt? Vel voluptas
            adipisci consequatur veritatis aspernatur cum, beatae
            necessitatibus. Sapiente illum consequuntur quasi!
          </Typography>
        </InformationBox>
      </Stack>
    </>
  );
}
