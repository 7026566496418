import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  IdentityDocumentType,
  MembershipStatus,
  MembershipType,
  ProductAdministratorSupportedServiceChannelType,
  ProductAdministratorSupportedServiceType,
} from "../../services/core-api-adapter";
import MembershipCard from ".";

function buildMembership(data?: any) {
  const membership = {
    productDetails: buildProductDetails(),
    members: [
      {
        isPrimaryCard: true,
        membershipDetails: {
          id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
          type: MembershipType.MAIN_MEMBER,
          number: "Member565",
          status: MembershipStatus.ACTIVE,
          beneficiaryCode: "00",
        },
        memberDetails: {
          id: "7f37be7c-31dc-448b-a6b6-a9b64f1b01f2",
          memberFullName: "Lesedi Persona",
          identityDocumentType: IdentityDocumentType.IdNumber,
          identityDocumentValue: "9905275800085",
          dateOfBirth: "1996-03-12",
        },
      },
    ],
  };

  return {
    ...membership,
    ...data,
  };
}

function buildProductDetails(data?: any) {
  const productDetails = {
    name: "Template",
    friendlyName: "Template (friendlyName)",
    code: "Template",
    seriesName: "Template",
    termsAndConditionsUri:
      "https://api.develop.unuhealth.org/api/Contract/TERMS_AND_CONDITIONS/1/File.html",
    brochureUri:
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    imageUri: "https://placehold.co/28x32/green/white?text=S",
    startDate: "2022-06-27T11:19:20+00:00",
    provider: {
      id: "f350c1f0-c8f8-46d6-8aae-519b32d8e187",
      name: "Genric",
      description: null,
      imageUri:
        "/components/MembershipCard/logos/b958fa21-4fd4-49d3-9a07-ed33b314538e.png",
    },
    underwriter: {},
    administrator: {
      id: "bbb82cc0-9ea1-40be-8cb2-80e3bbdf7b61",
      code: "NHG",
      name: "National Health Group",
      imageUri:
        "/components/MembershipCard/logos/4529ef13-b948-44e0-8976-9a6ef9a0b34b.png",
      callCenterNumber: "+27123456789",
      supportedServices: [
        {
          type: ProductAdministratorSupportedServiceType.CALL_CENTRE,
          channels: [
            {
              type: ProductAdministratorSupportedServiceChannelType.PHONE,
              value: "+27860222286",
              availability: [],
            },
            {
              type: ProductAdministratorSupportedServiceChannelType.EMAIL,
              value: "test@test.com",
              availability: [],
            },
            {
              type: ProductAdministratorSupportedServiceChannelType.WHATSAPP,
              value: null,
              availability: [],
            },
          ],
        },
        {
          type: ProductAdministratorSupportedServiceType.MEDICAL_ASSISTANCE,
          channels: [
            {
              type: ProductAdministratorSupportedServiceChannelType.PHONE,
              value: null,
              availability: [],
            },
            {
              type: ProductAdministratorSupportedServiceChannelType.EMAIL,
              value: null,
              availability: [],
            },
            {
              type: ProductAdministratorSupportedServiceChannelType.WHATSAPP,
              value: "+27727239355",
              availability: [],
            },
          ],
        },
        {
          type: ProductAdministratorSupportedServiceType.COUNSELLING_ASSISTANCE,
          channels: [
            {
              type: ProductAdministratorSupportedServiceChannelType.PHONE,
              value: "+27860006969",
              availability: [],
            },
            {
              type: ProductAdministratorSupportedServiceChannelType.EMAIL,
              value: null,
              availability: [],
            },
            {
              type: ProductAdministratorSupportedServiceChannelType.WHATSAPP,
              value: null,
              availability: [],
            },
          ],
        },
      ],
    },
    contactDetails: {
      callCenterNumber: "+27860222286",
      callCenterEmail: "test@test.com",
      counselingNumber: "+27860006969",
      whatsappNumber: "+27727239355",
    },
  };
  return {
    ...productDetails,
    ...data,
  };
}

export default function MembershipCardExamples() {
  return (
    <Stack spacing={2} divider={<Divider />}>
      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BEWELL 101"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 101",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BEWELL-101"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL-101",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BEWELL 101+"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 101+",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BEWELL 102"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 102",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BEWELL 103"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BEWELL 103",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BW101"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BW101",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "B101P"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "B101P",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BW102"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BW102",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "BW103"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "BW103",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "GLDHR"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "GLDHR",
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "Khululekha"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "Khululekha",
              administrator: {
                id: "697bd4e4-11bb-11ee-a27d-02412b37ae4b",
                code: "LIB",
                name: "Liberty Health",
                imageUri:
                  "/components/MembershipCard/logos/697bd4e4-11bb-11ee-a27d-02412b37ae4b.png",
                callCenterNumber: "+27123456789",
              },
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "Khululekha 01"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "Khululekha 01",
              administrator: {
                id: "697bd4e4-11bb-11ee-a27d-02412b37ae4b",
                code: "LIB",
                name: "Liberty Health",
                imageUri:
                  "/components/MembershipCard/logos/697bd4e4-11bb-11ee-a27d-02412b37ae4b.png",
                callCenterNumber: "+27123456789",
              },
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "Khululekha 02"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "Khululekha 02",
              administrator: {
                id: "697bd4e4-11bb-11ee-a27d-02412b37ae4b",
                code: "LIB",
                name: "Liberty Health",
                imageUri:
                  "/components/MembershipCard/logos/697bd4e4-11bb-11ee-a27d-02412b37ae4b.png",
                callCenterNumber: "+27123456789",
              },
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "Khululekha 03"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "Khululekha 02",
              administrator: {
                id: "697bd4e4-11bb-11ee-a27d-02412b37ae4b",
                code: "LIB",
                name: "Liberty Health",
                imageUri:
                  "/components/MembershipCard/logos/697bd4e4-11bb-11ee-a27d-02412b37ae4b.png",
                callCenterNumber: "+27123456789",
              },
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "Unu forFree"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "Unu forFree",
              administrator: {
                id: "697bd4e4-11bb-11ee-a27d-02412b37ae4b",
                code: "Unu forFree",
                name: "Unu forFree",
                imageUri:
                  "/components/MembershipCard/logos/697bd4e4-11bb-11ee-a27d-02412b37ae4b.png",
                callCenterNumber: "+27123456789",
              },
            }),
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "PRIST"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "PRIST",
              pharmacySwitchCode: "ENA0008",
              underwriter: {
                id: "f350c1f0-c8f8-46d6-8aae-519b32d8e187",
                name: "Genric",
                description: null,
                imageUri:
                  "/components/MembershipCard/logos/f350c1f0-c8f8-46d6-8aae-519b32d8e187.png",
              },
            }),
            members: [
              {
                isPrimaryCard: true,
                membershipDetails: {
                  id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
                  type: MembershipType.MAIN_MEMBER,
                  number: "Member565",
                  status: MembershipStatus.ACTIVE,
                  beneficiaryCode: "00",
                },
                memberDetails: {
                  id: "7f37be7c-31dc-448b-a6b6-a9b64f1b01f2",
                  memberFullName: "Lesedi Persona",
                  identityDocumentType: IdentityDocumentType.IdNumber,
                  identityDocumentValue: "9905275800085",
                  dateOfBirth: "1996-03-12",
                },
              },
            ],
          })}
        />
      </Box>

      <Box>
        <Typography variant="h3" textAlign="center">
          Product Code: "UNHANDLED_PRODUCT_CODE"
        </Typography>
        <br />
        <MembershipCard
          membership={buildMembership({
            productDetails: buildProductDetails({
              code: "UNHANDLED_PRODUCT_CODE",
            }),
          })}
        />
      </Box>
    </Stack>
  );
}
