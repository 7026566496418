import { Box, Stack, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import CircledBackButton from "../../components/CircledBackButton";
import { MarketplaceProductDetail } from "../../components/MarketplaceProductDetail";
import { borderRadiusContainerConfig } from "../../theme";

export default function MarketplaceProductDetailPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { productID } = useParams();

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <Stack
        bgcolor="secondary.main"
        sx={{
          pb: (theme: Theme) =>
            `${
              theme.shape.borderRadius *
              borderRadiusContainerConfig.containerBorderRadiusMultiplier
            }px`,
        }}
      >
        <Box sx={{ p: 2 }}>
          <CircledBackButton
            onClick={() => navigate("/home/marketplace")}
            label={t("MarketplaceProductDetailPage.backButtonLabel")}
            showLabel={true}
          />
        </Box>
      </Stack>

      <MarketplaceProductDetail productID={productID} />
    </Stack>
  );
}
