import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { trackEvent } from "../../../../services/analytics-adapter";
import { MedicalServiceProviderType } from "../../../../services/core-api-adapter";
import useGetMemberships from "../../../../hooks/useGetMemberships";
import ChatWithANurse from "../../../ChatWithANurse";
import CircledBackButton from "../../../CircledBackButton";
import GetMedicalAssistanceModal from "../../../GetMedicalAssistanceModal";
import HaveANurseCallMe from "../../../HaveANurseCallMe";

interface DefaultProps {
  showInformationPrompt?: boolean;
}

export function Default({ showInformationPrompt = false }: DefaultProps) {
  const navigate = useNavigate();
  const { firstMembership, memberships } = useGetMemberships();

  const [medicalProviderType, setMedicalProviderType] = useState<
    string | null
  >();

  useEffect(() => {
    const medicalServiceProvider =
      firstMembership?.productDetails.medicalServiceProvider;

    setMedicalProviderType(medicalServiceProvider);

    trackEvent({
      event: "action.getMedicalAssistanceTypeSelected",
      "getMedicalAssistance.type": "chat with a nurse",
      ...(medicalServiceProvider && {
        "getMedicalAssistance.source": medicalServiceProvider,
      }),
    });
  }, [memberships]);

  return (
    <>
      {showInformationPrompt === true ? (
        <GetMedicalAssistanceModal />
      ) : (
        <Stack justifyContent="space-between" minHeight="100%">
          <Box p={2}>
            <CircledBackButton showLabel={true} onClick={() => navigate(-1)} />
          </Box>
          <Box>
            {!medicalProviderType ||
            medicalProviderType === MedicalServiceProviderType.WHATSAPP ? (
              <ChatWithANurse />
            ) : (
              <HaveANurseCallMe />
            )}
          </Box>
        </Stack>
      )}
    </>
  );
}
