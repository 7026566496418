import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import theme from "../../theme";
import FullscreenBackground from "../FullscreenBackground";
import { useTranslation } from "react-i18next";
import { convertPxToRem } from "../../utils";
import { trackEvent } from "../../services/analytics-adapter";
import CircledBackButton from "../CircledBackButton";
import IconLoader from "../IconLoader";

interface ChatWithMedicalProfessionalProps {
  onContinue: Function;
  onBackButtonClick: Function;
  type: "NURSE" | "DOCTOR";
  amountDue: string;
}

export default function ChatWithMedicalProfessional({
  onContinue,
  type,
  amountDue,
  onBackButtonClick,
}: ChatWithMedicalProfessionalProps) {
  const { t } = useTranslation();

  function onContinueButtonClick() {
    onContinue();

    trackEvent({
      event: "action.ChatWithMedicalProfessional",
      source: "chat to medical assistant professional",
    });
  }

  return (
    <Stack
      color="primary.contrastText"
      sx={{
        height: "100%",
      }}
      p={2}
    >
      <Stack>
        <CircledBackButton
          showLabel={true}
          onClick={onBackButtonClick}
          labelColor="primary.contrastText"
        />
      </Stack>

      <Stack
        sx={{
          height: "100%",
        }}
        justifyContent="flex-end"
      >
        <IconLoader
          icon="ChatWithMedicalProfessionalIcon"
          color="primary"
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />

        <Stack textAlign="center" spacing={4} py={4}>
          <Typography variant="h1" color="inherit">
            {t(
              `ChatWithMedicalProfessional.${type.toLowerCase()}.title` as any
            )}
          </Typography>

          <Typography variant="h3" color="inherit" px={2}>
            {t(
              `ChatWithMedicalProfessional.${type.toLowerCase()}.subTitle` as any
            )}
          </Typography>

          <Stack spacing={4} px={2}>
            <Stack
              p={2}
              borderRadius={theme.shape.borderRadius}
              bgcolor={theme.palette.neutral[50]}
              textAlign="center"
            >
              <Typography
                color={theme.palette.neutral[700]}
                fontWeight={500}
                variant="body1"
              >
                {t(`ChatWithMedicalProfessional.amountDue`)}
              </Typography>
              <Typography variant="h1">{amountDue}</Typography>
            </Stack>

            <Stack
              color={theme.palette.neutral[50]}
              p={2}
              spacing={2}
              borderRadius={theme.shape.borderRadius}
              border={`1px solid ${theme.palette.neutral[50]}`}
              textAlign="left"
            >
              <Typography color="inherit" variant="h4">
                {t(`ChatWithMedicalProfessional.instructionsTitle`)}
              </Typography>
              {(
                t<any, any, string[]>(
                  `ChatWithMedicalProfessional.${type.toLowerCase()}.instructions` as any,
                  {
                    returnObjects: true,
                  }
                ) as []
              ).map((instruction, index) => (
                <Stack my={1} key={String(instruction) + String(index)}>
                  <Divider sx={{ borderColor: theme.palette.neutral[50] }} />
                  <Typography
                    sx={{ pt: 2 }}
                    fontWeight={600}
                    variant="body2"
                    color="inherit"
                  >
                    <Box
                      component="span"
                      display="inline-flex"
                      flexDirection="column"
                      bgcolor={theme.palette.neutral[50]}
                      borderRadius="50%"
                      height={convertPxToRem(25)}
                      width={convertPxToRem(25)}
                      lineHeight={convertPxToRem(25)}
                      textAlign="center"
                      mr={1}
                      sx={{
                        color: theme.palette.primary[700],
                      }}
                    >
                      {Number(index) + 1}
                    </Box>
                    {instruction}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Button
          sx={{
            mt: 8,
          }}
          onClick={onContinueButtonClick}
          color="info"
          fullWidth
        >
          {t("common.continueButton")}
        </Button>

        <FullscreenBackground color="primary.main" />
      </Stack>
    </Stack>
  );
}
