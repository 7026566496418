import { useTranslation } from "react-i18next";
import { slideUpModalStyle } from "../../theme";
import DefaultDialog from "../DefaultDialog";
import { useNavigate } from "react-router";
import { Button, Stack, Typography } from "@mui/material";
import {
  Member,
  Membership,
  getMembershipConfig,
} from "../../services/core-api-adapter";
import { isStringDefined } from "../../utils";
import { trackEvent } from "../../services/analytics-adapter";

interface MembershipOptionsModalProps {
  isOpen: boolean;
  membership: Membership | null;
  onClose?: Function;
}

export default function MembershipOptionsModal({
  isOpen,
  onClose,
  membership,
}: MembershipOptionsModalProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const defaultOnClose = () => navigate(-1);

  const member: Member | null =
    membership?.members.find((member) => member.isPrimaryCard === true) || null;

  const policyScheduleUri = membership?.productDetails?.policyScheduleUri;
  const policyWordingUri = membership?.productDetails?.policyWordingUri;

  const policyDocumentsExist: boolean =
    isStringDefined(policyScheduleUri) || isStringDefined(policyWordingUri);

  function onManagePlanAndPeopleButtonClick() {
    navigate(`/home/memberships/${member?.membershipDetails.id}/manage`);
  }

  function onViewContactDetailsButtonClick() {
    navigate(`/home/memberships/${member?.membershipDetails.id}/contact`);
  }

  function onViewTermsButtonClick() {
    navigate(`/home/memberships/${member?.membershipDetails.id}/terms`);
  }

  function onViewPlanBenefitsButtonClick() {
    navigate(`/home/my-benefits/benefits`);
  }

  function onDownloadPolicyDocumentsButtonClick() {
    trackEvent({
      event: "action.viewPolicyDocuments",
      "viewPolicyDocuments.source": "Membership options modal",
    });

    navigate(
      `/home/memberships/${member?.membershipDetails.id}/policy-documents`
    );
  }

  return (
    <DefaultDialog
      fullWidth={true}
      isOpen={isOpen}
      onClose={onClose || defaultOnClose}
      animate={true}
      paperStyleProps={{
        ...slideUpModalStyle,
      }}
      label={t("MembershipOptionsModal.title")}
    >
      <Stack spacing={2}>
        <Typography variant="h3" align="center" fontWeight={600}>
          {t("MembershipOptionsModal.title")}
        </Typography>

        {getMembershipConfig(membership)
          ?.areAllMembershipManagementSupportedActionsEnabled && (
          <Button
            variant="outlined"
            size="small"
            onClick={onManagePlanAndPeopleButtonClick}
          >
            {t("MembershipOptionsModal.manageButton")}
          </Button>
        )}

        <Button
          variant="outlined"
          size="small"
          onClick={onViewPlanBenefitsButtonClick}
        >
          {t("MembershipOptionsModal.viewBenefitsButton")}
        </Button>

        <Button
          variant="outlined"
          size="small"
          onClick={onViewContactDetailsButtonClick}
        >
          {t("MembershipOptionsModal.viewContactDetailsButton")}
        </Button>

        {membership?.productDetails?.termsAndConditionsUri && (
          <Button
            variant="outlined"
            size="small"
            onClick={onViewTermsButtonClick}
          >
            {t("MembershipOptionsModal.viewTermsButton")}
          </Button>
        )}

        {policyDocumentsExist === true && (
          <Button
            variant="outlined"
            size="small"
            onClick={onDownloadPolicyDocumentsButtonClick}
          >
            {t("MembershipOptionsModal.downloadPolicyDocuments")}
          </Button>
        )}
      </Stack>
    </DefaultDialog>
  );
}
