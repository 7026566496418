import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import useGetMemberships from "../../hooks/useGetMemberships";
import {
  findMembershipByMemberIdentifier,
  getMembershipSupportedServices,
  Membership,
  ProductAdministratorSupportedService,
  ProductAdministratorSupportedServiceChannelType,
} from "../../services/core-api-adapter";
import LoadingSpinner from "../LoadingSpinner";
import Panel from "../Panel";
import { buildWhatsAppUniversalLinkURL } from "../../utils";

interface MembershipContactDetailsPanelProps {
  membershipIdentifier?: string;
}

export default function MembershipContactDetailsPanel({
  membershipIdentifier,
}: MembershipContactDetailsPanelProps) {
  const { t } = useTranslation();

  const { memberships, membershipsFetchError, isMembershipsLoading } =
    useGetMemberships();
  const [membership, setMembership] = useState<Membership | null>(null);
  const [supportedServices, setSupportedServices] = useState<
    ProductAdministratorSupportedService[] | []
  >([]);

  useEffect(() => {
    setMembership(
      findMembershipByMemberIdentifier(membershipIdentifier || "", memberships)
    );
  }, [memberships, membershipIdentifier]);

  useEffect(() => {
    if (membership) {
      setSupportedServices(
        getMembershipSupportedServices(membership).filter(
          (supportedService) => {
            const combinedValuesForAllChannels =
              supportedService.channels.reduce((accumulator, channel) => {
                if (!channel.value) {
                  return accumulator;
                }
                return `${accumulator}${channel.value}`;
              }, "");
            return combinedValuesForAllChannels !== "";
          }
        )
      );
    }
  }, [membership]);

  return (
    <>
      <Stack>
        <Typography variant="h2">
          {membership?.productDetails.friendlyName}
        </Typography>
        <Typography variant="h3" fontWeight={600}>
          {t("MembershipContactDetailsPage.title")}
        </Typography>
      </Stack>

      <Panel>
        {isMembershipsLoading ? (
          <Stack alignItems="center">
            <LoadingSpinner />
          </Stack>
        ) : (
          <>
            {!membership || membershipsFetchError ? (
              <Typography variant="h4" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            ) : (
              <Stack spacing={3} divider={<Divider />}>
                {supportedServices &&
                  supportedServices.map((supportedService) => {
                    if (!supportedService) {
                      return null;
                    }
                    return (
                      <Stack
                        key={supportedService.type}
                        component="article"
                        spacing={1}
                        aria-labelledby={supportedService.type}
                      >
                        <Typography
                          variant="h3"
                          fontWeight={600}
                          id={supportedService.type}
                        >
                          {t(
                            `common.supportedServiceType.${supportedService.type}`
                          )}
                        </Typography>
                        <Stack spacing={1}>
                          {supportedService.channels.map((channel) => {
                            if (!channel.value) {
                              return null;
                            }
                            return (
                              <Box key={channel.type}>
                                <Typography variant="subtitle2">
                                  {t(
                                    `common.supportedServiceChannelType.${channel.type}`
                                  )}
                                </Typography>
                                {channel.type ===
                                  ProductAdministratorSupportedServiceChannelType.PHONE && (
                                  <Link
                                    href={`tel:${channel.value}`}
                                    target="_blank"
                                    fontWeight={600}
                                    sx={{ textDecoration: "none" }}
                                  >
                                    {channel.value}
                                  </Link>
                                )}

                                {channel.type ===
                                  ProductAdministratorSupportedServiceChannelType.EMAIL && (
                                  <Link
                                    href={`mailto:${channel.value}`}
                                    target="_blank"
                                    fontWeight={600}
                                    sx={{ textDecoration: "none" }}
                                  >
                                    {channel.value}
                                  </Link>
                                )}

                                {channel.type ===
                                  ProductAdministratorSupportedServiceChannelType.WHATSAPP && (
                                  <Link
                                    href={buildWhatsAppUniversalLinkURL(
                                      channel.value
                                    )}
                                    target="_blank"
                                    fontWeight={600}
                                    sx={{ textDecoration: "none" }}
                                  >
                                    {channel.value}
                                  </Link>
                                )}
                              </Box>
                            );
                          })}
                        </Stack>
                      </Stack>
                    );
                  })}
              </Stack>
            )}
          </>
        )}
      </Panel>
    </>
  );
}
