import { useEffect } from "react";
import Dashboard from "../../components/Dashboard";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function DashboardPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Home:Dashboard",
      pageSubSection1: "Home",
      pageSubSection2: "Home:Dashboard",
      pageCategory: "Home",
    });
  }, []);
  return <Dashboard />;
}
