import { useEffect, useState } from "react";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MedicineList, { Medicine } from "../MedicineList/index";
import MedicalConditionInput from "../MedicalConditionInput";
import Panel from "../Panel";
import IconLoader from "../IconLoader";

export enum QuestionStatusType {
  ALLERGIES = "Allergies",
  CONDITIONS = "Conditions",
}

interface Props {
  onChange: Function;
  type?: QuestionStatusType;
  value?: MedicalCondition[];
}

export interface MedicalCondition {
  value: string;
  medication: Medicine[];
}

export default function MedicalConditionList({
  type = QuestionStatusType.CONDITIONS,
  onChange,
  value,
}: Props) {
  const { t } = useTranslation();
  const [conditionList, setConditionList] = useState<MedicalCondition[]>([]);
  const [addConditionIsVisible, setAddConditionIsVisible] = useState(true);

  function onAdd(internalValue: string) {
    setConditionList((list) => {
      return [
        ...list,
        {
          value: internalValue,
          medication: [],
        },
      ];
    });
  }

  function onRemove(index: number) {
    setConditionList(conditionList.filter((_, i) => i !== index));
  }

  function onChangeMedication(
    medicineList: Medicine[],
    conditionIndex: number
  ) {
    setConditionList((prevConditionList) => {
      return prevConditionList.map((condition, index) => {
        return index === conditionIndex
          ? { ...condition, medication: medicineList }
          : condition;
      });
    });
  }

  useEffect(() => {
    const isPropsNotEqualToInternalState =
      JSON.stringify(value) !== JSON.stringify(conditionList);

    if (Array.isArray(value) && isPropsNotEqualToInternalState) {
      setConditionList(value);
    }
  }, [value]);

  useEffect(() => {
    onChange && onChange(conditionList);
  }, [conditionList]);

  return (
    <Stack spacing={2}>
      <Typography variant="h4">
        {t(`MedicalConditionList.${type}.listTitle`)}
      </Typography>

      <Stack
        flexGrow={2}
        spacing={1}
        role="list"
        aria-label={t(`MedicalConditionList.${type}.listTitle`)}
      >
        {conditionList.length > 0 &&
          conditionList.map((condition, index) => {
            const id = condition.value + String(index);
            return (
              <Panel key={id} role="listitem" aria-label={condition.value}>
                <Stack spacing={1}>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Stack spacing={1}>
                      <Typography variant="subtitle1">
                        {t(`MedicalConditionList.${type}.listLabel`)}
                      </Typography>
                      <Typography sx={{ wordBreak: "break-word" }} variant="h4">
                        {condition.value}
                      </Typography>
                    </Stack>
                    <IconButton
                      aria-label={t(
                        `MedicalConditionList.${type}.deleteButton`
                      )}
                      onClick={() => onRemove(index)}
                      sx={{
                        padding: 2,
                        top: (theme) => theme.spacing(-2),
                        alignSelf: "flex-start",
                      }}
                    >
                      <IconLoader icon="BinIcon" />
                    </IconButton>
                  </Stack>

                  <Stack spacing={1}>
                    {condition.medication.length > 0 && (
                      <Typography variant="subtitle1">
                        {t(`MedicineList.listLabel`)}
                      </Typography>
                    )}
                    <MedicineList
                      value={condition.medication}
                      onChange={(medication: Medicine[]) =>
                        onChangeMedication(medication, index)
                      }
                    />
                  </Stack>
                </Stack>
              </Panel>
            );
          })}
      </Stack>

      {addConditionIsVisible || conditionList.length === 0 ? (
        <Panel>
          <MedicalConditionInput
            type={`MedicalConditionList.${type}`}
            onChange={onAdd}
            hideInput={setAddConditionIsVisible}
          />
        </Panel>
      ) : (
        <Button
          variant="outlined"
          fullWidth
          color="neutral"
          onClick={() => setAddConditionIsVisible(true)}
        >
          + {t(`MedicalConditionList.${type}.addButton`)}
        </Button>
      )}
    </Stack>
  );
}
