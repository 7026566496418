import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { submitContractAgreement } from "../../services/core-api-adapter";
import CollectTerms from "../CollectTerms";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import { trackEvent } from "../../services/analytics-adapter";

interface CollectTermsAcceptanceContract {
  ContractType: string;
  Description: string;
  Version: number;
  FileUri: string;
}

interface CollectTermsAndConditionsProductAcceptanceProps {
  onSubmit: Function;
  data: CollectTermsAcceptanceContract;
}

export default function CollectTermsAndConditionsProductAcceptance(
  props: CollectTermsAndConditionsProductAcceptanceProps
) {
  const { t } = useTranslation();

  const termsAndConditionFileURLFromSuppliedData = props.data?.FileUri || "";
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  function onFormSubmit(event: FormEvent<HTMLFormElement>) {
    setIsSubmittingForm(true);

    event.preventDefault();
    submitContractAgreement({
      contractType: props.data?.ContractType,
      version: String(props.data?.Version) || "",
    })
      .catch(() => setIsSubmittingForm(false))
      .then(() => {
        setIsSubmittingForm(false);
        onDone();
      });
  }

  function onDone() {
    trackEvent({
      event: "action.termsAndConditionsAccepted",
      source: "productTermsAndConditions",
    });
    props.onSubmit();
  }

  function onTermsReject() {
    trackEvent({
      event: "action.termsAndConditionsRejected",
      source: "productTermsAndConditions",
    });
  }

  return (
    <>
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <>
          <CollectTerms
            onFormSubmit={onFormSubmit}
            onTermsReject={onTermsReject}
            termsTitle={t("CollectTermsAndConditionsProductAcceptance.title")}
            termsMessage="CollectTermsAndConditionsProductAcceptance.termsAndConditions.message"
            termsAndConditionFileURLFromSuppliedData={
              termsAndConditionFileURLFromSuppliedData
            }
            data={props.data}
            contractType={props.data.ContractType}
          />
        </>
      )}
    </>
  );
}
