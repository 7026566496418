import { Divider, Stack, Typography } from "@mui/material";
import DateOfBirthInput, { DOBFormData } from ".";

export default function DoBInputPage() {
  function onChange(data: DOBFormData, validatedTotalDate: boolean) {
    console.log("Collect data and validation status", data, validatedTotalDate);
  }
  return (
    <>
      <DateOfBirthInput onChange={onChange} />
      <DateOfBirthInput value={"2001-10-12"} onChange={onChange} />
      <DateOfBirthInput value={"2001-01-01"} onChange={onChange} />
      <DateOfBirthInput value={"1984-02-29"} onChange={onChange} />
      <DateOfBirthInput value={"1985-02-29"} onChange={onChange} />
      <DateOfBirthInput value={"1899-12-31"} onChange={onChange} />
      <DateOfBirthInput value={"2023-12-31"} onChange={onChange} />
      <DateOfBirthInput value={"2222-35-35"} onChange={onChange} />
      <Stack mt={2} spacing={2}>
        <Divider />
        <Typography>No input label</Typography>
        <DateOfBirthInput
          value={"2222-35-35"}
          onChange={onChange}
          showLabel={false}
        />
        <Typography>Customized placeholder</Typography>
        <DateOfBirthInput
          placeholder={{
            day: "DD",
            month: "MM",
            year: "YYYY",
          }}
          value={"2222-35-35"}
          onChange={onChange}
          showLabel={false}
        />
      </Stack>
    </>
  );
}
