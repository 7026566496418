import { Button, Stack, Typography } from "@mui/material";
import FullscreenBackground from "../FullscreenBackground";
import { useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";
import theme from "../../theme";

interface PaymentSuccessProps {
  onContinue: Function;
}

export default function PaymentSuccess({ onContinue }: PaymentSuccessProps) {
  const { t } = useTranslation();

  function onContinueButtonClick() {
    onContinue();
  }

  return (
    <Stack
      justifyContent="flex-end"
      color="primary.contrastText"
      sx={{
        height: "100%",
      }}
      p={2}
    >
      <IconLoader
        icon="TrolleyWithFilledCheckIcon"
        color={theme.palette.neutral[50] as any}
        sx={{
          fontSize: convertPxToRem(150),
          alignSelf: "center",
        }}
      />

      <Stack textAlign="center" spacing={4} py={4}>
        <Typography variant="h1" color="inherit">
          {t("PaymentSuccess.title")}
        </Typography>
        <Stack spacing={1}>
          {(
            t<any, any, string[]>(`PaymentSuccess.subTitle`, {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography
              variant="h2"
              color="inherit"
              key={String(text) + String(index)}
            >
              {text}
            </Typography>
          ))}
        </Stack>

        <Stack spacing={2}>
          {(
            t<any, any, string[]>(`PaymentSuccess.body`, {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography
              variant="body1"
              color="inherit"
              key={String(text) + String(index)}
            >
              {text}
            </Typography>
          ))}
        </Stack>
      </Stack>

      <Button onClick={onContinueButtonClick} color="info" fullWidth>
        {t("common.continueButton")}
      </Button>

      <FullscreenBackground color="primary.main" />
    </Stack>
  );
}
