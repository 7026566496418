import { useEffect } from "react";
import Messages from "../../components/Messages";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function MessagesPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Notifications:Notifications list",
      pageSubSection1: "Notifications",
      pageSubSection2: "Notifications:Notifications list",
      pageCategory: "Notifications",
    });
  }, []);
  return <Messages />;
}
