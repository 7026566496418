import { useTranslation } from "react-i18next";
import {
  findMainMemberInMembership,
  Member,
} from "../../services/core-api-adapter";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { trackEvent } from "../../services/analytics-adapter";
import WatermarkIcon from "../WatermarkIcon";
import { useEffect, useState } from "react";
import useGetMemberships from "../../hooks/useGetMemberships";
import HaveANurseCallMeSuccessModal from "../HaveANurseCallMeSuccessModal";
import { useNavigate } from "react-router";
import IconLoader from "../IconLoader";
import useSeekMedicalAssistance from "../../hooks/useSeekMedicalAssistance";
import { useGlobalStore } from "../../store";
import DefaultError from "../DefaultError";
import MedicalAssistanceMembersDropdown from "../MedicalAssistanceMembersDropdown";
import theme from "../../theme";

export default function HaveANurseCallMe() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    makeCallMeBackRequest,
    isTooManyRequestsError,
    isAssistanceRequestInProgress,
    isGenericError,
  } = useSeekMedicalAssistance();
  const { state } = useGlobalStore();

  const [showGenericError, setShowGenericError] = useState(false);

  const {
    firstMembership,
    memberships,
    isMembershipsLoading,
    membershipsFetchError,
  } = useGetMemberships();

  const [isHaveANurseCallMeModalOpen, setIsHaveANurseCallMeModalOpen] =
    useState(false);

  const [selectedMemberMembershipID, setSelectedMemberMembershipID] = useState<
    string | null | undefined
  >(null);

  const [mainMember, setMainMember] = useState<Member | null>(null);
  const [shouldShowMembersDropdown, setShouldShowMembersDropdown] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (isMembershipsLoading === false && membershipsFetchError === false) {
      const hasDependents = firstMembership.members.length > 1;

      setMainMember(
        findMainMemberInMembership(firstMembership, state.currentUser.memberId)
      );

      if (!hasDependents) {
        setSelectedMemberMembershipID(
          findMainMemberInMembership(
            firstMembership,
            state.currentUser.memberId
          )?.membershipDetails.id
        );
      }

      setShouldShowMembersDropdown(hasDependents);
    }
  }, [
    shouldShowMembersDropdown,
    memberships,
    isMembershipsLoading,
    membershipsFetchError,
  ]);

  function handleSetSelectedMemberMembershipID(
    selectedMemberMembershipID: any
  ) {
    setSelectedMemberMembershipID(selectedMemberMembershipID);
  }

  function onHaveANurseCallMeHandler() {
    const membershipId =
      selectedMemberMembershipID || mainMember?.membershipDetails?.id || null;

    trackEvent({
      event: "action.getMedicalAssistanceRequested",
      "getMedicalAssistance.channel": "have a nurse call me modal",
    });

    makeCallMeBackRequest({
      assistanceMembershipId: String(membershipId),
    }).then(() => {
      setIsHaveANurseCallMeModalOpen(true);
    });
  }

  function onModalOkayClickHandler() {
    setIsHaveANurseCallMeModalOpen(false);
    navigate("/home/get-medical-assistance/prompt");
  }

  useEffect(() => {
    setShowGenericError(!!isGenericError);
  }, [isTooManyRequestsError, isAssistanceRequestInProgress, isGenericError]);

  if (showGenericError === true) {
    return (
      <DefaultError>
        <Typography variant="h2">{t("GenericError.title")}</Typography>
        <Typography pb={2}>{t("GenericError.content")}</Typography>
        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            onClick={() => navigate("/home")}
            variant="contained"
          >
            {t("common.comeBackButton")}
          </Button>
        </Stack>
      </DefaultError>
    );
  }

  return (
    <Stack sx={{ p: 2 }} justifyContent="space-between" spacing={2}>
      <>
        {showGenericError === false && (
          <HaveANurseCallMeSuccessModal
            isOpen={isHaveANurseCallMeModalOpen}
            onSubmit={onModalOkayClickHandler}
            onClose={onModalOkayClickHandler}
          />
        )}
      </>

      <IconLoader sx={{ fontSize: 54 }} icon="FaceIconBrandLarge" />

      {shouldShowMembersDropdown === false && mainMember !== null && (
        <Stack spacing={1}>
          <Typography variant="h3">
            {t("ChatWithANurse.heading", {
              name: mainMember.memberDetails.memberFullName.split(" ")[0],
            })}
          </Typography>
          <Typography variant="h3" pb={2} fontWeight={600}>
            {t("HaveANurseCallMe.callMeSubtitle")}
          </Typography>
        </Stack>
      )}

      {shouldShowMembersDropdown === true && (
        <MedicalAssistanceMembersDropdown
          setSelectedMembershipId={handleSetSelectedMemberMembershipID}
          memberships={memberships}
          selectMemberLabel={t("HaveANurseCallMe.selectMemberLabel")}
          selectMemberPlaceholder={t(
            "HaveANurseCallMe.selectMemberPlaceholder"
          )}
        />
      )}

      <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          <WatermarkIcon
            height={42}
            width={42}
            color={theme.palette.accent2.light}
            foreground={"primary"}
            Icon={"WhatsAppIcon"}
          />

          <Typography variant="body1" marginLeft={1.5}>
            {t("HaveANurseCallMe.point1")}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center">
          <WatermarkIcon
            height={42}
            width={42}
            color={theme.palette.accent2.light}
            foreground={"primary"}
            Icon={"PillBottleIcon"}
          />
          <Typography variant="body1" marginLeft={1.5}>
            {t("HaveANurseCallMe.point2")}
          </Typography>
        </Stack>
      </Stack>

      {shouldShowMembersDropdown && (
        <Stack spacing={1} justifyContent="center" textAlign={"center"}>
          <Typography fontWeight={600} variant="h3">
            {t("HaveANurseCallMe.callMeTitle")}
          </Typography>

          <Typography pb={2} fontWeight={600}>
            {t("HaveANurseCallMe.callMeSubtitle")}
          </Typography>
        </Stack>
      )}

      {!shouldShowMembersDropdown && (
        <Box px={(theme) => theme.spacing(2)}>
          <Divider
            sx={{
              alignSelf: "center",
              width: "100%",
            }}
          />
        </Box>
      )}

      <Button
        onClick={onHaveANurseCallMeHandler}
        startIcon={<IconLoader icon="TelephoneIcon" />}
        disabled={!selectedMemberMembershipID || isAssistanceRequestInProgress}
      >
        {t("common.haveANurseCallMe")}
      </Button>
    </Stack>
  );
}
