import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  Member,
  MembershipChangeRequestIntent,
  submitMembershipChangeRequest,
} from "../../services/core-api-adapter";
import LoadingSpinner from "../LoadingSpinner";
import MembershipManagementChangeRequestFormResponse, {
  MembershipManagementChangeRequestResponseTypes,
} from "../MembershipManagementChangeRequestFormResponse";

export enum MembershipChangeRequestActionType {
  ADD_DEPENDENT = "AddDependent",
  EDIT_DEPENDENT = "EditDependent",
  REMOVE_DEPENDENT = "RemoveDependent",
  CHANGE_PLAN = "ChangePlan",
  CANCEL_PLAN = "CancelPlan",
}

interface MembershipManagementChangeRequestFormProps {
  dependent?: Member;
  Icon: any;
  membershipActionType: MembershipChangeRequestActionType;
  membershipIdentifier?: string;
  onCancel?: () => void;
  onClose?: () => void;
  onSuccess?: () => void;
}

enum states {
  COLLECTING_FORM_DATA = "COLLECTING_FORM_DATA",
  SUBMITTING_FORM_DATA = "SUBMITTING_FORM_DATA",
  SUBMISSION_SUCCEEDED = "SUBMISSION_SUCCEEDED",
  SUBMISSION_FAILED = "SUBMISSION_FAILED",
}

export default function MembershipManagementChangeRequestForm({
  membershipIdentifier = "",
  onCancel,
  onClose,
  onSuccess,
  dependent,
  membershipActionType,
  Icon,
}: MembershipManagementChangeRequestFormProps) {
  const { t } = useTranslation();

  const [state, setState] = useState(states.COLLECTING_FORM_DATA);

  function getIntent(
    actionType: MembershipChangeRequestActionType
  ): MembershipChangeRequestIntent {
    switch (actionType) {
      case MembershipChangeRequestActionType.CHANGE_PLAN:
        return MembershipChangeRequestIntent.CHANGE_PLAN;
      case MembershipChangeRequestActionType.ADD_DEPENDENT:
        return MembershipChangeRequestIntent.ADD_DEPENDENT;
      case MembershipChangeRequestActionType.CANCEL_PLAN:
        return MembershipChangeRequestIntent.CANCEL_PLAN;
      case MembershipChangeRequestActionType.REMOVE_DEPENDENT:
        return MembershipChangeRequestIntent.REMOVE_DEPENDENT;
      case MembershipChangeRequestActionType.EDIT_DEPENDENT:
        return MembershipChangeRequestIntent.UPDATE_DEPENDENT;
    }
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const controller = new AbortController();

    setState(states.SUBMITTING_FORM_DATA);

    submitMembershipChangeRequest(
      {
        intent: getIntent(membershipActionType),
        membershipIdentifier: membershipIdentifier,
      },
      {
        signal: controller.signal,
      }
    )
      .then(() => {
        if (onSuccess) {
          onSuccess();
        }
        setState(states.SUBMISSION_SUCCEEDED);
      })
      .catch(() => {
        if (controller.signal.aborted === false) {
          setState(states.SUBMISSION_FAILED);
        }
      });
  }

  return (
    <>
      {state === states.COLLECTING_FORM_DATA && (
        <form onSubmit={onSubmit}>
          <Stack component="article" spacing={2}>
            <Box textAlign="center">{Icon}</Box>

            <Typography textAlign="center" variant="h3" fontWeight="600">
              {t(`MembershipManagement${membershipActionType}Form.title`)}
            </Typography>

            {(
              t<any, any, string[]>(
                `MembershipManagement${membershipActionType}Form.body`,
                {
                  fullName: dependent?.memberDetails?.memberFullName,
                  returnObjects: true,
                }
              ) as []
            ).map((text: string) => (
              <Typography key={text} textAlign="center">
                {text}
              </Typography>
            ))}

            <Stack component="footer" spacing={1}>
              <Button type="submit" size="small" fullWidth>
                {t("common.continueButton")}
              </Button>

              {onCancel && (
                <Button
                  size="small"
                  variant="text"
                  fullWidth
                  onClick={onCancel}
                >
                  {t("common.cancelButton")}
                </Button>
              )}
            </Stack>
          </Stack>
        </form>
      )}

      {state === states.SUBMITTING_FORM_DATA && (
        <Box textAlign="center">
          <LoadingSpinner />
        </Box>
      )}

      {state === states.SUBMISSION_SUCCEEDED && (
        <MembershipManagementChangeRequestFormResponse
          onClose={onClose}
          responseType={
            MembershipManagementChangeRequestResponseTypes["SUCCESS"]
          }
        />
      )}

      {state === states.SUBMISSION_FAILED && (
        <MembershipManagementChangeRequestFormResponse
          onClose={onClose}
          responseType={
            MembershipManagementChangeRequestResponseTypes["FAILURE"]
          }
        />
      )}
    </>
  );
}
