import { Button, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { SnackbarContext } from "../../context/SnackbarProvider";

export default function DefaultSnackbarExamples() {
  const { showSnackbar } = useContext(SnackbarContext);

  return (
    <>
      <Typography variant="h3" my={3}>
        Snackbars with autoclosing after 3s delay, no animation
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => {
            showSnackbar({ message: "info", type: "info" });
          }}
        >
          Info
        </Button>
        <Button
          onClick={() => {
            showSnackbar({ message: "error", type: "error" });
          }}
        >
          Error
        </Button>
        <Button
          onClick={() => {
            showSnackbar({ message: "success", type: "success" });
          }}
        >
          Success
        </Button>
        <Button
          onClick={() => {
            showSnackbar({ message: "warning", type: "warning" });
          }}
        >
          warning
        </Button>
      </Stack>
      <Typography variant="h3" my={3}>
        Snackbars with autoclosing after 2s delay, with slide animation
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button
          onClick={() => {
            showSnackbar({
              message: "info",
              type: "info",
              animate: true,
              direction: "down",
            });
          }}
        >
          Info
        </Button>
        <Button
          onClick={() => {
            showSnackbar({
              message: "error",
              type: "error",
              animate: true,
              direction: "down",
            });
          }}
        >
          Error
        </Button>
        <Button
          onClick={() => {
            showSnackbar({
              message: "success",
              type: "success",
              animate: true,
              direction: "down",
            });
          }}
        >
          Success
        </Button>
        <Button
          onClick={() => {
            showSnackbar({
              message: "warning",
              type: "warning",
              animate: true,
              direction: "down",
            });
          }}
        >
          warning
        </Button>
      </Stack>
    </>
  );
}
