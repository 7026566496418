import { Stack } from "@mui/material";
import MarketplaceSelfServiceConfirmation from ".";

export default function MarketplaceSelfServiceConfirmationExamples() {
  return (
    <Stack sx={{ height: "100vh", p: 2 }}>
      <MarketplaceSelfServiceConfirmation />
    </Stack>
  );
}
