import { useParams } from "react-router";
import MembershipManagement from "../../components/MembershipManagement";
import { useEffect } from "react";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function MembershipManagementPage() {
  const { membershipIdentifier } = useParams();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Membership:Manage membership",
      pageSubSection1: "Membership",
      pageSubSection2: "Membership:Manage membership",
      pageCategory: "Membership",
    });
  }, [membershipIdentifier]);

  return <MembershipManagement membershipIdentifier={membershipIdentifier} />;
}
