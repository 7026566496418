import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IdentityDocumentType } from "../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import { AuthIdentificationType, useGlobalStore } from "../../store";
import { replaceSubjectWithString } from "../../utils";
import IconLoader from "../IconLoader";
import AuthIdentificationTypeSelectionV1 from "./components/v1";
import AuthIdentificationTypeSelectionV2 from "./components/v2";

export interface AuthIdentificationTypeOption {
  id: AuthIdentificationType;
  label: string;
  icon?: any;
  identityDocumentSubType?: string;
}

interface AuthIdentificationTypeSelectionProps {
  onContinue: (selectedAuthIdentificationType: string) => void;
}

export default function AuthIdentificationTypeSelection({
  onContinue,
}: AuthIdentificationTypeSelectionProps) {
  const { t } = useTranslation();
  const [isUNUIDModalOpen, setIsUNUIDModalOpen] = useState(false);
  const [selectedAuthIdentificationType, setSelectedAuthIdentificationType] =
    useState<AuthIdentificationTypeOption | null>(null);

  const { dispatch, state } = useGlobalStore();

  useEffect(() => {
    const userSelectedAuthIdentificationType =
      state.userSelectedAuthIdentificationType.value;
    if (
      isFeatureEnabled(
        FeatureToggleIdentifier.ENABLE_AUTH_IDENTIFICATION_TYPE_SELECTION
      )
    ) {
      if (userSelectedAuthIdentificationType) {
        onContinue(userSelectedAuthIdentificationType);
      }
    } else {
      onContinue("");
    }
  }, []);

  const authIdentificationTypesV1: AuthIdentificationTypeOption[] = [
    {
      id: AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT,
      label: t(
        `AuthIdentificationTypeSelection.options.${AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT}.buttonLabel`
      ),
      icon: <IconLoader icon="PassportIcon" />,
    },
    {
      id: AuthIdentificationType.UNUID,
      label: t(
        `AuthIdentificationTypeSelection.options.${replaceSubjectWithString(
          AuthIdentificationType.UNUID,
          IdentityDocumentType.UNUID,
          "UNUID"
        )}.buttonLabel` as any
      ),
      icon: <IconLoader icon="PassportIcon" />,
    },
  ];

  const authIdentificationTypesV2: AuthIdentificationTypeOption[] = [
    {
      id: AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT,
      label: t("common.IdNumber"),
      identityDocumentSubType: "IdNumber",
    },
    {
      id: AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT,
      label: t("common.PassportNumber"),
      identityDocumentSubType: "PassportNumber",
    },
    {
      id: AuthIdentificationType.UNUID,
      label: t(
        `AuthIdentificationTypeSelection.options.${replaceSubjectWithString(
          AuthIdentificationType.UNUID,
          IdentityDocumentType.UNUID,
          "UNUID"
        )}.buttonLabel` as any
      ),
      identityDocumentSubType: "UnuId",
    },
  ];

  const closeUNUIDModal = () => {
    setIsUNUIDModalOpen(false);
  };

  const onWhatIsAnUNUIDLinkClick = () => {
    setIsUNUIDModalOpen(true);
  };

  const onContinueButtonClick = () => {
    const authenticationIdType = selectedAuthIdentificationType?.id || "";
    const authenticationIdSubType =
      selectedAuthIdentificationType?.identityDocumentSubType || "";

    dispatch({
      type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
      payload: {
        userSelectedAuthIdentificationType: authenticationIdType,
        userSelectedAuthIdentificationSubType: authenticationIdSubType,
      },
    });

    onContinue(authenticationIdType);
  };

  const onUNUIDButtonClick = () => {
    const authenticationIdType = AuthIdentificationType.UNUID;
    const authenticationIdSubType = "UnuId";

    dispatch({
      type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
      payload: {
        userSelectedAuthIdentificationType: authenticationIdType,
        userSelectedAuthIdentificationSubType: authenticationIdSubType,
      },
    });

    onContinue(authenticationIdType);
  };

  const onSwitchAuthTypeHandler = () => {
    const authenticationIdType =
      AuthIdentificationType.ZA_ID_OR_INTERNATIONAL_PASSPORT;
    const authenticationIdSubType =
      selectedAuthIdentificationType?.identityDocumentSubType || "";

    dispatch({
      type: "SET_USER_AUTH_IDENTIFICATION_TYPE",
      payload: {
        userSelectedAuthIdentificationType: authenticationIdType,
        userSelectedAuthIdentificationSubType: authenticationIdSubType,
      },
    });

    onContinue(authenticationIdType);
  };

  return (
    <>
      {isFeatureEnabled(FeatureToggleIdentifier.ENABLE_OPTIMISE_SIGN_IN) ? (
        <AuthIdentificationTypeSelectionV2
          authIdentificationTypes={authIdentificationTypesV2}
          selectedAuthIdentificationType={selectedAuthIdentificationType}
          setSelectedAuthIdentificationType={setSelectedAuthIdentificationType}
          onContinueButtonClick={onContinueButtonClick}
          onUNUIDButtonClick={onUNUIDButtonClick}
        />
      ) : (
        <AuthIdentificationTypeSelectionV1
          isUNUIDModalOpen={isUNUIDModalOpen}
          closeUNUIDModal={closeUNUIDModal}
          onSwitchAuthTypeHandler={onSwitchAuthTypeHandler}
          authIdentificationTypes={authIdentificationTypesV1}
          selectedAuthIdentificationType={selectedAuthIdentificationType}
          onWhatIsAnUNUIDLinkClick={onWhatIsAnUNUIDLinkClick}
          setSelectedAuthIdentificationType={setSelectedAuthIdentificationType}
          onContinueButtonClick={onContinueButtonClick}
        />
      )}
    </>
  );
}
