import { useNavigate, useParams } from "react-router";
import HealthMeasurementDetail from "../../components/HealthMeasurementDetail";
import { HealthMeasurementQuestionType } from "../../services/core-api-adapter";
import { Stack } from "@mui/material";
import CircledBackButton from "../../components/CircledBackButton";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
} from "../../theme";
import { useEffect } from "react";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function HealthMeasurementDetailPage() {
  const navigate = useNavigate();
  const { healthMeasurementIdentifier } = useParams();

  function onBackButtonClick() {
    navigate("/home/my-health/health-profile");
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "My health:Health measurement detail",
      pageSubSection1: "My health",
      pageSubSection2: "My health:Health measurement detail",
      pageCategory: "My health",
    });
  }, [healthMeasurementIdentifier]);

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
        ...pageWithBottomNavigationStyling,
      }}
    >
      <Stack p={1}>
        <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
      </Stack>

      <HealthMeasurementDetail
        healthMeasurementIdentifier={
          healthMeasurementIdentifier as HealthMeasurementQuestionType
        }
      />
    </Stack>
  );
}
