import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import CircledBackButton from "../../components/CircledBackButton";
import MembershipDetailPanel from "../../components/MembershipDetailPanel";
import { membershipManagementPageStyling } from "../../theme";
import { useEffect } from "react";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function MembershipDetailPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { membershipIdentifier } = useParams();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Membership:Membership detail",
      pageSubSection1: "Membership",
      pageSubSection2: "Membership:Membership detail",
      pageCategory: "Membership",
    });
  }, [membershipIdentifier]);

  return (
    <Stack spacing={2} sx={{ ...membershipManagementPageStyling }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <CircledBackButton showLabel={true} onClick={() => navigate(-1)} />
      </Stack>

      <Typography component="h1" variant="h2">
        {t("MembershipDetailPage.title")}
      </Typography>

      <MembershipDetailPanel membershipIdentifier={membershipIdentifier} />
    </Stack>
  );
}
