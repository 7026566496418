import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthIdentificationTypeOption } from "../..";
import { GetHelpFABConfig } from "../../../../theme";
import { convertPxToRem } from "../../../../utils";
import GetHelpCTAButton from "../../../GetHelpCTAButton";
import IconLoader from "../../../IconLoader";
import SwitchAuthIdentificationTypeModal, {
  ModalKeys,
} from "../../../SwitchAuthIdentificationTypeModal";

interface AuthIdentificationTypeSelectionV1Props {
  isUNUIDModalOpen: boolean;
  closeUNUIDModal: () => void;
  onSwitchAuthTypeHandler: () => void;
  authIdentificationTypes: AuthIdentificationTypeOption[];
  selectedAuthIdentificationType: AuthIdentificationTypeOption | null;
  onWhatIsAnUNUIDLinkClick: () => void;
  setSelectedAuthIdentificationType: (
    authIdentificationType: AuthIdentificationTypeOption
  ) => void;
  onContinueButtonClick: () => void;
}

export default function AuthIdentificationTypeSelectionV1({
  isUNUIDModalOpen,
  closeUNUIDModal,
  onSwitchAuthTypeHandler,
  authIdentificationTypes,
  selectedAuthIdentificationType,
  onWhatIsAnUNUIDLinkClick,
  setSelectedAuthIdentificationType,
  onContinueButtonClick,
}: AuthIdentificationTypeSelectionV1Props) {
  const { t } = useTranslation();
  return (
    <>
      <SwitchAuthIdentificationTypeModal
        isOpen={isUNUIDModalOpen}
        onClose={closeUNUIDModal}
        extras={{
          onRatherSignInWithDifferentAuthType: onSwitchAuthTypeHandler,
          modalLabelKey: ModalKeys.WHAT_IS_AN_UNUID,
          authTypeLabel: ModalKeys.RSA_ID_OR_PASSPORT,
        }}
      />
      <Stack
        justifyContent="space-between"
        flexGrow={1}
        sx={{
          p: 2,
          minHeight: "100%",
        }}
      >
        <IconLoader
          icon="UnuLogo"
          color="primary"
          sx={{
            fontSize: convertPxToRem(102),
            alignSelf: "center",
          }}
        />

        <Stack spacing={1}>
          <Typography variant="h3" textAlign="center">
            {t("AuthIdentificationTypeSelection.title")}
          </Typography>

          <Stack spacing={2} p={2}>
            {authIdentificationTypes.map(
              (authIdentificationType: AuthIdentificationTypeOption) => {
                const isActive =
                  authIdentificationType.id ===
                  selectedAuthIdentificationType?.id;
                return (
                  <Button
                    key={
                      authIdentificationType.label + authIdentificationType.id
                    }
                    fullWidth
                    onClick={() =>
                      setSelectedAuthIdentificationType(authIdentificationType)
                    }
                    variant={isActive ? "contained" : "outlined"}
                    startIcon={authIdentificationType.icon}
                    color="neutral"
                  >
                    {authIdentificationType.label}
                  </Button>
                );
              }
            )}
          </Stack>
          <Button variant="text" onClick={onWhatIsAnUNUIDLinkClick}>
            {t("WhatIsAnUNUIDModal.title")}
          </Button>
        </Stack>
        <Stack spacing={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignSelf="flex-end"
            position="relative"
            width={GetHelpFABConfig.floatingActionButtonWidth}
          >
            <GetHelpCTAButton eventName="action.contactSupportFab" />
          </Stack>

          <Button
            disabled={!selectedAuthIdentificationType}
            onClick={onContinueButtonClick}
          >
            {t("common.nextButton")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
