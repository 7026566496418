import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { defaultFullscreenPageStyling } from "../../theme";
import RefreshReverseIcon from "../../theme/icons/RefreshReverseIcon";
import { getConfigurationVariable } from "../../services/configuration-adapter";
import { trackEvent } from "../../services/analytics-adapter";

export default function ErrorFallbackWithReload() {
  const { t } = useTranslation();

  function onGetSupportLinkClick() {
    trackEvent({
      event: "action.supportRequested",
      source: "error fallback with reload screen",
    });
  }

  return (
    <Stack
      justifyContent="space-between"
      flexDirection="row"
      sx={{
        ...defaultFullscreenPageStyling,
      }}
    >
      <Stack width="100%" spacing={2} justifyContent="flex-end">
        <Typography variant="h2" pb={2}>
          {t("common.genericErrorMessage")}
        </Typography>
        <Button
          fullWidth
          onClick={() => window.location.reload()}
          variant="contained"
          endIcon={<RefreshReverseIcon />}
        >
          {t("common.reloadText")}
        </Button>
        <Button
          component="a"
          href={getConfigurationVariable("VITE_APP_UNU_HEALTH_SUPPORT_URI")}
          onClick={onGetSupportLinkClick}
          target="_blank"
          fullWidth
          variant="outlined"
        >
          {t("common.supportLink")}
        </Button>
      </Stack>
    </Stack>
  );
}
