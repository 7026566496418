import { Button, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RejectTermsOrConsentDialog from "../RejectTermsOrConsentDialog";
import GetHelpCTAButton from "../GetHelpCTAButton";
import { GetHelpFABConfig } from "../../theme";
import { useGlobalStore } from "../../store";

interface TermsResponseFormProps {
  onFormSubmit: FormEventHandler<HTMLFormElement>;
  onTermsReject: () => void;
}

export default function TermsResponseForm({
  onFormSubmit,
  onTermsReject,
}: TermsResponseFormProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();

  const [isFormSubmitEnabled, setFormSubmitEnabled] = useState(false);
  const [
    isRejectTermsOrConsentDialogOpen,
    setIsRejectTermsOrConsentDialogOpen,
  ] = useState(false);
  const [acceptanceValue, setAcceptanceValue] = useState<null | boolean>(null);

  useEffect(() => {
    setFormSubmitEnabled(acceptanceValue === true);
  }, [acceptanceValue]);

  function onRejectTermsOrConsentDialogClose() {
    setIsRejectTermsOrConsentDialogOpen(false);
  }

  function onRejectTermsOrConsentButtonClick() {
    setIsRejectTermsOrConsentDialogOpen(true);
    onTermsReject();
  }

  useEffect(() => {
    if (isRejectTermsOrConsentDialogOpen === false) {
      setAcceptanceValue(null);
    }
  }, [isRejectTermsOrConsentDialogOpen]);

  return (
    <>
      <form onSubmit={onFormSubmit}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <ToggleButtonGroup
              color="neutral"
              size="large"
              value={acceptanceValue}
              exclusive
              onChange={(_, newValue: null | boolean) => {
                if (newValue === false) {
                  onRejectTermsOrConsentButtonClick();
                }
                setAcceptanceValue(newValue);
              }}
              fullWidth
            >
              <ToggleButton value={true}>{t("common.iAgree")}</ToggleButton>
              <ToggleButton value={false}>
                {t("common.iDontAgree")}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>

          {state.currentUser.isRetailMember === false && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignSelf="flex-end"
              position="relative"
              width={GetHelpFABConfig.floatingActionButtonWidth}
            >
              <GetHelpCTAButton />
            </Stack>
          )}
          <Button
            type="submit"
            color="primary"
            disabled={!isFormSubmitEnabled}
            fullWidth
          >
            {t("common.continueButton")}
          </Button>
        </Stack>
      </form>
      <RejectTermsOrConsentDialog
        isOpen={isRejectTermsOrConsentDialogOpen}
        onClose={onRejectTermsOrConsentDialogClose}
      />
    </>
  );
}
