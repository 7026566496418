import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button, Stack, Typography } from "@mui/material";
import { useGlobalStore } from "../../store";
import { logout } from "../../services/core-api-adapter";
import { defaultFullscreenPageStyling } from "../../theme";
import ProcessIndicatorIcon from "../ProcessIndicatorIcon";

export default function MemberProvisioningError() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();

  function onComeBackButtonClick() {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
      navigate("/");
    });
  }

  return (
    <Stack
      justifyContent="flex-end"
      sx={{
        ...defaultFullscreenPageStyling,
      }}
    >
      <Stack spacing={2} justifyContent="space-between">
        <Stack sx={{ opacity: 0.5, textAlign: "center", mb: 6 }}>
          <ProcessIndicatorIcon type="error" height={90} width={90} />
        </Stack>
        <Typography variant="h2">
          {t("MemberProvisioningError.title")}
        </Typography>
        <Typography variant="body1" color="neutral" sx={{ pb: 3 }}>
          {t("MemberProvisioningError.body")}
        </Typography>
        <Button onClick={onComeBackButtonClick} variant="contained">
          {t("common.comeBackButton")}
        </Button>
      </Stack>
    </Stack>
  );
}
