import { Avatar, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { submitMemberAcknowledgeEvent } from "../../services/core-api-adapter";
import { useGlobalStore } from "../../store";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import IconLoader from "../IconLoader";

interface ProvisionedEmployeeWelcomeProps {
  onSubmit: Function;
}

export default function ProvisionedEmployeeWelcome({
  onSubmit,
}: ProvisionedEmployeeWelcomeProps) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const firstName = state.currentUser.firstName;

  function onContinueButtonClick() {
    setIsSubmittingForm(true);
    submitMemberAcknowledgeEvent("PROVISIONED_EMPLOYEE_WELCOME").finally(() => {
      setIsSubmittingForm(false);
      onSubmit();
    });
  }

  return (
    <>
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FaceIconBrandLarge />
            <IconLoader icon="LinkAlternateIcon" color="action" />
            <Avatar
              alt={state.currentUser.organizationName}
              src={state.currentUser.organizationImageUri}
              sx={{ width: 54, height: 54 }}
            />
          </Stack>
          <Typography variant="h3">
            {firstName?.length > 0
              ? t("ProvisionedEmployeeWelcome.title.withName", {
                  name: firstName,
                })
              : t("ProvisionedEmployeeWelcome.title.withoutName")}
          </Typography>

          {(
            t<any, any, string[]>("ProvisionedEmployeeWelcome.body", {
              returnObjects: true,
            }) as []
          ).map((text: string, index: number) => (
            <Typography key={String(text + index)}>{text}</Typography>
          ))}

          <Button fullWidth onClick={onContinueButtonClick}>
            {t("common.nextButton")}
          </Button>
          {!state.currentUser.isRetailMember && firstName?.length > 0 && (
            <>
              <Typography variant="body2" align="center">
                {t("ProvisionedEmployeeWelcome.notThisPersonMessage", {
                  name: firstName,
                })}
              </Typography>

              <Typography variant="h4" color="neutral.700" align="center">
                {t("ProvisionedEmployeeWelcome.contactEmployerButtonLabel")}
              </Typography>
            </>
          )}
        </Stack>
      )}
    </>
  );
}
