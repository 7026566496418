import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useMachine } from "@xstate/react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetMemberRequiredActionsItem } from "../../services/core-api-adapter";
import { useGlobalStore } from "../../store";
import { memberRequiredActionsFlowMachine as machine } from "./machine";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import FlowHeader from "../FlowHeader";

import NoProductsLinkedToMemberError from "../NoProductsLinkedToMemberError";
import MemberProvisioningError from "../MemberProvisioningError";
import ProvisionedEmployeeWelcome from "../ProvisionedEmployeeWelcome";
import CollectTermsAcceptance from "../CollectTermsAcceptance";
import CollectTermsAndConditionsProductAcceptance from "../CollectTermsAndConditionsProductAcceptance";
import CollectConsentAcceptance from "../CollectConsentAcceptance";
import CollectMarketingCommunicationPreferences from "../CollectMarketingCommunicationPreferences";
import AccountCreationSuccess from "../AccountCreationSuccess";
import BinahScanFlow from "../BinahScanFlow";
import { trackEvent } from "../../services/analytics-adapter";
import CollectMemberOnboardingDetails from "../CollectMemberOnboardingDetails";

interface CollectTermsAcceptanceContract {
  ContractType: string;
  Version: number;
  FileUri: string;
}

const combinedPlatformContractKeys = [
  "TERMS_AND_CONDITIONS",
  "TERMS_AND_CONDITIONS_PLATFORM",
  "TERMS_AND_CONDITIONS_PLATFORM_ZA",
  "TERMS_AND_CONDITIONS_PLATFORM_MZ",
  "TERMS_AND_CONDITIONS_PLATFORM_UG",
  "COOKIE_POLICY",
  "PRIVACY_POLICY",
];

function buildCollectTermsAcceptanceContractList(
  requiredActions: GetMemberRequiredActionsItem[]
): CollectTermsAcceptanceContract[] {
  return requiredActions
    .filter((requiredAction) => {
      return combinedPlatformContractKeys.indexOf(requiredAction.name) > -1;
    })
    .map((requiredAction) => {
      return JSON.parse(requiredAction.data || "{}");
    });
}

function getRequiredActionPresenter(
  requiredActions: GetMemberRequiredActionsItem[],
  onCompletedRequiredAction: Function
) {
  const requiredAction = requiredActions[0] || { name: "" };
  const requiredActionNameNormalised =
    combinedPlatformContractKeys.indexOf(requiredAction.name) > -1
      ? "COMBINED_PLATFORM_CONTRACTS"
      : requiredAction.name;

  const requiredActionPresenterDefault = {
    name: requiredActionNameNormalised,
    titleKey: `MemberRequiredActionsFlow.${requiredActionNameNormalised}.title`,
    stepValue: 0,
    isComplete: false,
    hideFlowHeader: false,
    component: null,
    hideOverFlowX: false,
  };

  switch (requiredActionNameNormalised) {
    case "NO_ACTIVE_PLANS":
      return {
        ...requiredActionPresenterDefault,
        titleKey: "",
        hideFlowHeader: true,
        component: <NoProductsLinkedToMemberError />,
      };
    case "PROVISIONING_ERROR":
      return {
        ...requiredActionPresenterDefault,
        titleKey: "",
        hideFlowHeader: true,
        component: <MemberProvisioningError />,
      };
    case "PROVISIONED_EMPLOYEE_WELCOME":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 2,
        component: (
          <Box sx={{ p: 2 }}>
            <ProvisionedEmployeeWelcome onSubmit={onCompletedRequiredAction} />
          </Box>
        ),
      };
    case "COMBINED_PLATFORM_CONTRACTS":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 2,
        component: (
          <Box sx={{ p: 2 }}>
            <CollectTermsAcceptance
              data={buildCollectTermsAcceptanceContractList(requiredActions)}
              onSubmit={onCompletedRequiredAction}
            />
          </Box>
        ),
      };
    case "TERMS_AND_CONDITIONS_PRODUCT":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 2,
        component: (
          <Box sx={{ p: 2 }}>
            <CollectTermsAndConditionsProductAcceptance
              data={JSON.parse(requiredAction.data || "{}")}
              onSubmit={onCompletedRequiredAction}
            />
          </Box>
        ),
      };
    case "CONSENT_FOR_DATA_PROCESSING":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 2,
        component: (
          <Box sx={{ p: 2 }}>
            <CollectConsentAcceptance
              data={JSON.parse(requiredAction.data || "{}")}
              onSubmit={onCompletedRequiredAction}
            />
          </Box>
        ),
      };
    case "COMMUNICATION_CONSENT":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 3,
        component: (
          <Box sx={{ p: 2 }}>
            <CollectMarketingCommunicationPreferences
              onSubmit={onCompletedRequiredAction}
            />
          </Box>
        ),
      };
    case "ACCOUNT_CREATION_SUCCESS":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 4,
        hideFlowHeader: true,
        hideOverFlowX: true,
        component: (
          <Box>
            <AccountCreationSuccess onSubmit={onCompletedRequiredAction} />
          </Box>
        ),
      };
    case "BINAH_SCAN":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 1,
        hideFlowHeader: true,
        component: (
          <BinahScanFlow onCompletedFlow={onCompletedRequiredAction} />
        ),
      };
    case "MEMBER_ONBOARDING":
      return {
        ...requiredActionPresenterDefault,
        stepValue: 1,
        hideFlowHeader: true,
        component: (
          <Stack p={2}>
            <CollectMemberOnboardingDetails
              data={JSON.parse(requiredAction.data || "{}")}
              onCompletedFlow={onCompletedRequiredAction}
            />
          </Stack>
        ),
      };
    default:
      return {
        ...requiredActionPresenterDefault,
        hideFlowHeader: true,
      };
  }
}

export default function MemberRequiredActionsFlow() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStore();
  const [machineState, dispatchMachineEvent] = useMachine(machine);

  function onCompletedRequiredAction() {
    dispatchMachineEvent({ type: "COMPLETED_REQUIRED_ACTION" });
  }

  const requiredActions = machineState.context.getMemberRequiredActionsResponse;
  const requiredActionPresenter = getRequiredActionPresenter(
    requiredActions,
    onCompletedRequiredAction
  );

  useEffect(() => {
    if (requiredActionPresenter?.name) {
      trackEvent({
        event: "action.memberRequiredActionStarted",
        actionName: requiredActionPresenter?.name,
      });
    }

    if (
      machineState.matches("exit") ||
      machineState.matches("gettingMemberRequiredActionsError")
    ) {
      dispatch({
        type: "SET_MEMBER_REQUIRED_ACTIONS_HAS_BEEN_COMPLETED",
      });
      navigate("/home");
    }
  }, [machineState.value]);

  return (
    <>
      {machineState.matches("gettingMemberRequiredActions") && (
        <FullscreenLoadingIndicator isOpen={true} mainIconType="face" />
      )}

      {machineState.matches("collectingRequiredAction") && (
        <Stack
          justifyContent="space-between"
          spacing={2}
          sx={{
            height: "100%",
            overflowX: !requiredActionPresenter.hideOverFlowX
              ? "hidden"
              : "unset",
          }}
        >
          {requiredActionPresenter.hideFlowHeader === true ? null : (
            <FlowHeader
              title={
                requiredActionPresenter.titleKey
                  ? t(requiredActionPresenter.titleKey as any)
                  : ""
              }
              value={requiredActionPresenter.stepValue}
              max={4}
              isComplete={requiredActionPresenter.isComplete || false}
              isBackButtonVisible={false}
            />
          )}
          {requiredActionPresenter.component}
        </Stack>
      )}
    </>
  );
}
