import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography } from "@mui/material";
import useGetMemberships from "../../hooks/useGetMemberships";
import {
  findMembershipByMemberIdentifier,
  Membership,
} from "../../services/core-api-adapter";
import LoadingSpinner from "../LoadingSpinner";
import { overrideDefaultLinkStyling } from "../../theme";
import Panel from "../Panel";
import IconLoader from "../IconLoader";
import { isStringDefined } from "../../utils";
import { trackEvent } from "../../services/analytics-adapter";

interface MembershipPolicyDocumentsProps {
  membershipIdentifier?: string;
}

export default function MembershipPolicyDocuments({
  membershipIdentifier,
}: MembershipPolicyDocumentsProps) {
  const { t } = useTranslation();

  const { memberships, membershipsFetchError, isMembershipsLoading } =
    useGetMemberships();
  const [membership, setMembership] = useState<Membership | null>(null);

  const [policyScheduleUri, setPolicyScheduleUri] = useState<
    string | null | undefined
  >(undefined);
  const [policyWordingUri, setPolicyWordingUri] = useState<
    string | null | undefined
  >(undefined);

  function onDownloadPolicyScheduleButtonClick() {
    trackEvent({
      event: "action.downloadPolicyDocuments",
      downloadedDocument: "Policy schedule",
    });
  }

  function onDownloadPolicyWordingButtonClick() {
    trackEvent({
      event: "action.downloadPolicyDocuments",
      downloadedDocument: "Policy wording",
    });
  }

  useEffect(() => {
    const foundMembership = findMembershipByMemberIdentifier(
      membershipIdentifier || "",
      memberships
    );

    setMembership(foundMembership);

    if (isStringDefined(foundMembership?.productDetails?.policyScheduleUri)) {
      setPolicyScheduleUri(membership?.productDetails?.policyScheduleUri);
    }

    if (isStringDefined(foundMembership?.productDetails?.policyWordingUri)) {
      setPolicyWordingUri(membership?.productDetails?.policyWordingUri);
    }
  }, [
    memberships,
    membershipIdentifier,
    membership?.productDetails?.policyScheduleUri,
    membership?.productDetails?.policyWordingUri,
  ]);

  return (
    <Stack
      spacing={2}
      flexGrow={1}
      justifyContent="space-between"
      sx={{ minHeight: "100%" }}
    >
      {isMembershipsLoading ? (
        <Stack alignItems="center" sx={{ p: 2 }}>
          <LoadingSpinner />
        </Stack>
      ) : (
        <>
          {!membership || membershipsFetchError ? (
            <Box p={2}>
              <Typography variant="h4" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            </Box>
          ) : (
            <Stack spacing={2}>
              {policyScheduleUri && (
                <Panel padding={0}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    component="a"
                    href={policyScheduleUri}
                    target="_blank"
                    p={2}
                    sx={{ ...overrideDefaultLinkStyling }}
                    onClick={onDownloadPolicyScheduleButtonClick}
                  >
                    <Typography variant="h4" color="primary">
                      {t("MembershipPolicyDocumentsPage.policySchedule.title")}
                    </Typography>
                    <IconLoader icon="DownloadIcon" color="primary" />
                  </Stack>
                </Panel>
              )}

              {policyWordingUri && (
                <Panel padding={0}>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    component="a"
                    href={policyWordingUri}
                    target="_blank"
                    p={2}
                    sx={{ ...overrideDefaultLinkStyling }}
                    onClick={onDownloadPolicyWordingButtonClick}
                  >
                    <Typography variant="h4" color="primary">
                      {t("MembershipPolicyDocumentsPage.policyWording.title")}
                    </Typography>
                    <IconLoader icon="DownloadIcon" color="primary" />
                  </Stack>
                </Panel>
              )}
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}
