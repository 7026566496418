import { Box, Stack, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import CircledBackButton from "../../components/CircledBackButton";
import FullscreenBackground from "../../components/FullscreenBackground";
import MarketplaceProductList from "../../components/MarketplaceProductList";
import { borderRadiusContainerConfig } from "../../theme";

export default function MarketplacePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { productIDs } = useParams();

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <Stack
        bgcolor="secondary.main"
        sx={{
          pb: (theme: Theme) =>
            `${
              theme.shape.borderRadius *
              borderRadiusContainerConfig.containerBorderRadiusMultiplier
            }px`,
        }}
      >
        <Box sx={{ p: 2 }}>
          <CircledBackButton
            showLabel={true}
            onClick={() => navigate("/home")}
          />
        </Box>
        <Stack spacing={1} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h1">{t("Marketplace.title")}</Typography>
          <Typography variant="body1">
            {t("Marketplace.description")}
          </Typography>
        </Stack>
      </Stack>

      <Box
        flexGrow={1}
        p={2}
        bgcolor="neutral.50"
        sx={{
          borderRadius:
            borderRadiusContainerConfig.containerBorderRadiusMultiplier,
          position: "relative",
          top: (theme: Theme) =>
            `-${
              theme.shape.borderRadius *
              borderRadiusContainerConfig.containerBorderRadiusMultiplier
            }px`,
        }}
      >
        <MarketplaceProductList productIDs={productIDs || ""} />
      </Box>
      <FullscreenBackground color="secondary.main" />
    </Stack>
  );
}
