import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MembershipCard from "../MembershipCard";
import { useEffect } from "react";
import { trackEvent } from "../../services/analytics-adapter";
import useGetMemberships from "../../hooks/useGetMemberships";
import LoadingSpinner from "../LoadingSpinner";
import useGetProductType from "../../hooks/useGetProductType";

export default function MembershipPanel() {
  const { t } = useTranslation();

  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();

  const { isPAYGProduct } = useGetProductType(firstMembership);

  useEffect(() => {
    trackEvent({
      event: "action.membershipCardViewed",
      source: "benefits",
    });
  }, []);

  return (
    <>
      {isMembershipsLoading ? (
        <Stack alignItems="center">
          <LoadingSpinner />
        </Stack>
      ) : (
        <Stack
          bgcolor="secondary.700"
          sx={{
            borderRadius: 2.5,
          }}
        >
          <Typography p={2} variant="h4">
            {memberships && firstMembership?.productDetails?.name}
          </Typography>
          <Stack
            bgcolor="background.paper"
            sx={{
              border: (theme) => "1px solid " + theme.palette.secondary[700],
              borderRadius: 2.5,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              p: 2,
              pt: 4,
            }}
          >
            {membershipsFetchError && (
              <Typography variant="h2" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            )}

            {memberships && memberships?.length > 0 && (
              <>
                <MembershipCard membership={firstMembership} />
                {!isPAYGProduct && (
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{ color: (theme) => theme.palette.neutral[500], mt: 4 }}
                  >
                    {t("common.membershipCardDisplaySuggestion")}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
