import { useEffect } from "react";
import More from "../../components/More";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function MorePage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "More:More",
      pageSubSection1: "More",
      pageSubSection2: "More:More",
      pageCategory: "More",
    });
  }, []);

  return <More />;
}
