import { Box, Button, Stack, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useGetMyBenefits from "../../hooks/useGetMyBenefits";
import Panel from "../Panel";
import LoadingSpinner from "../LoadingSpinner";

import theme, { panelStyle } from "../../theme";
import InformationBox from "../InformationBox";
import WatermarkIcon from "../WatermarkIcon";
import ProductBenefitAccordion from "../ProductBenefitAccordion";
import { getMembershipCallCentreServicePhoneChannel } from "../../services/core-api-adapter";
import { useId } from "react";

export default function ProductBenefitsPanel() {
  const { t } = useTranslation();

  const { products, productsFetchError, isProductsLoading } =
    useGetMyBenefits();

  const panelTitleId = useId();

  if (isProductsLoading) {
    return (
      <Panel>
        <Stack alignItems="center">
          <LoadingSpinner />
        </Stack>
      </Panel>
    );
  }

  if (!isProductsLoading && (productsFetchError || products.length === 0)) {
    return (
      <Panel>
        <Typography textAlign="center">
          {t("common.somethingWentWrong")}
        </Typography>
      </Panel>
    );
  }

  return (
    <Stack spacing={2}>
      {products.map((product) => {
        return (
          <Box
            key={product.name}
            flexGrow={1}
            sx={{
              ...panelStyle,
              p: 0,
              backgroundColor: (theme) => theme.palette.secondary.main,
              borderRadius: 2.5,
            }}
            component="article"
            aria-labelledby={panelTitleId}
          >
            <Typography
              p={2}
              component="h2"
              variant="h3"
              sx={{
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundImage: "url('/theme/patterns/pattern1.png')",
                  height: "100%",
                  left: 0,
                  opacity: 0.15,
                  pointerEvents: "none",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  zIndex: 1,
                }}
              />
              <Box id={panelTitleId} sx={{ position: "relative", zIndex: 2 }}>
                {product.friendlyName}
              </Box>
            </Typography>

            <Stack
              bgcolor="background.paper"
              sx={{
                border: (theme: Theme) =>
                  `1px solid ${theme.palette.secondary[700]}`,
                borderRadius: 2.5,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                p: 2,
              }}
              spacing={4}
            >
              {product.benefitCategory?.map((benefitCategory) => {
                return (
                  <Stack key={benefitCategory.name} spacing={2}>
                    <Typography variant="h4">{benefitCategory.name}</Typography>
                    <Stack spacing={1}>
                      {benefitCategory.benefits.map((benefit) => {
                        const config = {
                          icon:
                            benefit.waitingPeriod !== null ? (
                              <>
                                <WatermarkIcon
                                  extra={{
                                    alignSelf: "center",
                                    fontSize: (theme: Theme) =>
                                      theme.spacing(4),
                                  }}
                                  iconSize="medium"
                                  color={theme.palette.primary[300]}
                                  foreground="primary"
                                  Icon={"ClockIcon"}
                                />
                              </>
                            ) : (
                              <WatermarkIcon
                                extra={{
                                  alignSelf: "center",
                                  fontSize: (theme: Theme) => theme.spacing(4),
                                }}
                                iconSize="medium"
                                color={theme.palette.primary[300]}
                                foreground="primary"
                                Icon={"CheckmarkIcon"}
                              />
                            ),
                          subtitle: benefit.waitingPeriod
                            ? t("ProductBenefitsPanel.waitingPeriod")
                            : null,
                        };

                        return (
                          <ProductBenefitAccordion
                            key={benefit.name}
                            icon={config.icon}
                            subtitle={config?.subtitle || ""}
                            benefit={benefit}
                          />
                        );
                      })}
                    </Stack>
                  </Stack>
                );
              })}

              {getMembershipCallCentreServicePhoneChannel(product)?.value && (
                <Stack spacing={2}>
                  <InformationBox Icon={"PadlockIcon"}>
                    <Stack spacing={2}>
                      <Typography variant="body2" fontWeight="600">
                        {t("ProductBenefitsPanel.overviewWarning.title")}
                      </Typography>
                      <Typography variant="body2">
                        {t("ProductBenefitsPanel.overviewWarning.body")}
                      </Typography>
                      <Button
                        component="a"
                        href={`tel:${
                          getMembershipCallCentreServicePhoneChannel(product)
                            ?.value
                        }`}
                        variant="outlined"
                        size="small"
                        color="neutral"
                      >
                        {t("ProductBenefitsPanel.overviewWarning.callButton", {
                          phoneNumber:
                            getMembershipCallCentreServicePhoneChannel(product)
                              ?.value,
                        })}
                      </Button>
                    </Stack>
                  </InformationBox>
                </Stack>
              )}
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
}
