import { useEffect } from "react";
import { MedicalAssistance } from "../../components/MedicalAssistance";
import { trackPageViewV2 } from "../../services/analytics-adapter";

interface MedicalAssistancePageProps {
  showInformationPrompt?: boolean;
}
export default function MedicalAssistancePage({
  showInformationPrompt,
}: MedicalAssistancePageProps) {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Medical assistance:Get medical assistance",
      pageSubSection1: "Medical assistance",
      pageSubSection2: "Medical assistance:Get medical assistance",
      pageCategory: "Medical assistance",
    });
  }, []);
  return <MedicalAssistance showInformationPrompt={showInformationPrompt} />;
}
