import { Button, Divider, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useNavigate } from "react-router";
import { convertPxToRem } from "../../utils";
import CircledBackButton from "../CircledBackButton";
import theme from "../../theme";
import MessageItem from "./components/MessageItem";
import useGetMessages from "../../hooks/useGetMessages";
import LoadingSpinner from "../LoadingSpinner";
import IconLoader from "../IconLoader";
import { useState } from "react";
import { markAllMessagesAsRead } from "../../services/core-api-adapter";

export default function Messages() {
  const navigate = useNavigate();

  const [isMarkAllAsReadLoading, setIsMarkAllAsReadLoading] =
    useState<boolean>(false);
  const { messages, messagesFetchError, isMessagesLoading, refetchMessages } =
    useGetMessages();

  function onBackButtonClick() {
    navigate("/");
  }

  function markAllMessagesAsReadButtonClicked() {
    setIsMarkAllAsReadLoading(true);

    markAllMessagesAsRead()
      .then(() => {
        refetchMessages();
      })
      .finally(() => {
        setIsMarkAllAsReadLoading(false);
      });
  }

  return (
    <Stack
      sx={{
        height: "100%",
        overflowX: "hidden",
      }}
    >
      <Stack
        p={2}
        spacing={2}
        sx={{
          backgroundColor: theme.palette.neutral[100],
          borderBottom: `1px solid ${theme.palette.neutral[300]}`,
        }}
      >
        <CircledBackButton showLabel={true} onClick={onBackButtonClick} />

        <Stack spacing={2} alignItems="start">
          <Typography
            variant="h1"
            fontWeight={600}
            fontSize={convertPxToRem(26)}
          >
            {t("Messages.title")}
          </Typography>

          {messages && messages.length > 0 && (
            <Button
              onClick={markAllMessagesAsReadButtonClicked}
              size="small"
              variant="outlined"
              color="primary"
            >
              {t("Messages.markAllAsReadButton")}
            </Button>
          )}
        </Stack>
      </Stack>

      {isMessagesLoading ||
        (isMarkAllAsReadLoading && (
          <Stack position="relative" height="100%" alignSelf="center">
            <Stack
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <LoadingSpinner />
            </Stack>
          </Stack>
        ))}

      {!isMessagesLoading && !isMarkAllAsReadLoading && messagesFetchError && (
        <Typography alignSelf="center">
          {t("common.somethingWentWrong")}
        </Typography>
      )}

      {!isMessagesLoading &&
        !isMarkAllAsReadLoading &&
        !messagesFetchError &&
        messages && (
          <>
            {messages.length > 0 ? (
              <Stack
                component="ul"
                divider={<Divider />}
                aria-label={t("Messages.ariaLabel")}
              >
                {messages.map((message) => (
                  <MessageItem key={message.id} message={message} />
                ))}
              </Stack>
            ) : (
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
              >
                <IconLoader
                  icon="MessageIcon"
                  sx={{
                    fontSize: convertPxToRem(130),
                  }}
                />
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color="neutral.light"
                >
                  {t("Messages.noMessages.subtitle")}
                </Typography>
              </Stack>
            )}
          </>
        )}
    </Stack>
  );
}
