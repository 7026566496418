import { Divider, Typography } from "@mui/material";
import IconLoader from ".";
import CheckVerifiedFilledIcon from "../../theme/icons/CheckVerifiedFilledIcon";

export default function IconLoaderExamples() {
  return (
    <>
      <Typography variant="h2">IconLoader</Typography>

      <IconLoader
        icon="CheckVerifiedFilledIcon"
        fontSize="small"
        color="success"
      />
      <CheckVerifiedFilledIcon fontSize="small" color="success" />

      <Divider />

      <IconLoader icon="CheckVerifiedFilledIcon" color="success" />
      <CheckVerifiedFilledIcon color="success" />

      <Divider />

      <IconLoader
        icon="CheckVerifiedFilledIcon"
        fontSize="medium"
        color="success"
      />
      <CheckVerifiedFilledIcon fontSize="medium" color="success" />

      <Divider />

      <IconLoader
        icon="CheckVerifiedFilledIcon"
        fontSize="large"
        color="success"
      />
      <CheckVerifiedFilledIcon fontSize="large" color="success" />

      <Divider />

      <Typography variant="h1">
        <span>Hello</span>
        <IconLoader
          icon="CheckVerifiedFilledIcon"
          fontSize="inherit"
          color="success"
        />
        <CheckVerifiedFilledIcon fontSize="inherit" color="success" />
      </Typography>

      <Divider />
    </>
  );
}
