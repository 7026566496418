import { Stack } from "@mui/material";
import { useParams } from "react-router";
import MarketplaceProductComparison from "../../components/MarketplaceProductComparison";

export default function MarketplaceProductComparisonPage() {
  const { productIDs } = useParams();

  return (
    <Stack sx={{ minHeight: "100%" }}>
      <MarketplaceProductComparison productIDs={productIDs || ""} />
    </Stack>
  );
}
