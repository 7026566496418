import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { shadows } from "../../theme";
import IconLoader from "../IconLoader";

const buttonStyleOverride = {
  borderRadius: 6,
  padding: 4,
  "&, &:hover, &:focus": {
    boxShadow: shadows.light,
  },
  "&:disabled": {
    boxShadow: shadows.none,
  },
};

interface Props {
  options: {
    name: string;
    value: string;
  }[];
  label: string;
  min?: number;
  max?: number;
  selectedOption?: string | null;
  initialValue?: number;
  onChange?: Function;
}

export default function FrequencyInput({
  options,
  selectedOption,
  label,
  min,
  max,
  initialValue,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const valueMinimum = min || 0;
  const valueMaximum = max || 1000;

  const [isIncrementDisabled, setIsIncrementDisabled] = useState(true);
  const [isDecrementDisabled, setIsDecrementDisabled] = useState(true);

  const [internalValue, setInternalValue] = useState(0);
  const [interval, setIntervalValue] = useState<string | null>(null);

  useEffect(() => {
    const initialValueNormalised = Number(initialValue) || valueMinimum;

    setIsIncrementDisabled(
      limitValueToBounds(initialValueNormalised) === valueMaximum
    );
    setIsDecrementDisabled(
      limitValueToBounds(initialValueNormalised) === valueMinimum
    );
    setInternalValue(limitValueToBounds(initialValueNormalised));
    setIntervalValue(selectedOption || null);
  }, [initialValue, selectedOption]);

  useEffect(() => {
    onChange &&
      onChange({
        value: internalValue,
        interval: interval,
      });
  }, [internalValue, interval]);

  function limitValueToBounds(value: number): number {
    const minimumValue = value < valueMinimum ? valueMinimum : value;
    return minimumValue > valueMaximum ? valueMaximum : minimumValue;
  }

  function updateValue(value: number) {
    const valueLimitedToMinimumAndMaximumBounds = limitValueToBounds(value);
    setInternalValue(valueLimitedToMinimumAndMaximumBounds);
    setIsIncrementDisabled(
      valueLimitedToMinimumAndMaximumBounds >= valueMaximum
    );
    setIsDecrementDisabled(
      valueLimitedToMinimumAndMaximumBounds <= valueMinimum
    );
  }

  function onIncrementButtonClick() {
    updateValue(internalValue + 1);
  }

  function onDecrementButtonClick() {
    updateValue(internalValue - 1);
  }

  function onFrequencyChange(
    _event: MouseEvent<HTMLElement>,
    selectedFrequencyValue: string | null
  ) {
    setIntervalValue(selectedFrequencyValue);
  }

  return (
    <Stack spacing={4}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        role="spinbutton"
        aria-label={label}
      >
        <Button
          sx={buttonStyleOverride}
          color="info"
          onClick={onDecrementButtonClick}
          aria-label={t("FrequencyInput.decrementButton")}
          disabled={isDecrementDisabled}
        >
          <IconLoader icon="MinusSymbolIcon" />
        </Button>
        <Typography variant="h1" aria-label={t("FrequencyInput.valueLabel")}>
          {internalValue}
        </Typography>
        <Button
          sx={buttonStyleOverride}
          color="info"
          onClick={onIncrementButtonClick}
          aria-label={t("FrequencyInput.incrementButton")}
          disabled={isIncrementDisabled}
        >
          <IconLoader icon="PlusIcon" />
        </Button>
      </Stack>

      <ToggleButtonGroup
        color="neutral"
        value={interval}
        exclusive
        onChange={onFrequencyChange}
        fullWidth
      >
        {options.map(function (intervalOption, index) {
          return (
            <ToggleButton
              key={String(intervalOption.name + index)}
              value={intervalOption.value}
            >
              {intervalOption.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Stack>
  );
}
