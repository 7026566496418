import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";

export default function WhyAreWeAskingYouThisModal({
  isOpen,
  onClose,
  extras,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t("WhyAreWeAskingYouThisModal.title")}
      actionButtons={
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      }
    >
      <Typography align="center">{extras}</Typography>
      <Typography align="center">
        {t("WhyAreWeAskingYouThisModal.defaultSignOff")}
      </Typography>
    </DefaultDialog>
  );
}
