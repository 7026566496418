import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Divider, Stack, Typography } from "@mui/material";
import useGetMemberships from "../../hooks/useGetMemberships";
import {
  findMembershipByMemberIdentifier,
  getMembershipCallCentreServicePhoneChannel,
  Membership,
  MembershipChangeRequest,
  MembershipChangeRequestFormType,
  MembershipStatus,
  MembershipType,
} from "../../services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "../../services/feature-toggle-adapter";
import LoadingSpinner from "../LoadingSpinner";
import MembershipCard from "../MembershipCard";
import Panel from "../Panel";
import MembershipOptionsModal from "../MembershipOptionsModal";
import MembershipDetailDependentsList from "../MembershipDetailDependentsList";
import SlideUpDialog from "../SlideUpDialog";
import MembershipManagementChangeRequestForm, {
  MembershipChangeRequestActionType,
} from "../MembershipManagementChangeRequestForm";
import IconLoader from "../IconLoader";
import theme from "../../theme";
import useGetProductType from "../../hooks/useGetProductType";
import { useNavigate } from "react-router";

const MAX_ALLOWED_DEPENDENTS = 3;
const MAX_DEPENDENT_AGE = 18;
interface MembershipDetailPanelProps {
  membershipIdentifier?: string;
}

export default function MembershipDetailPanel({
  membershipIdentifier = "",
}: MembershipDetailPanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { memberships, membershipsFetchError, isMembershipsLoading } =
    useGetMemberships();
  const [mainMemberMembership, setMainMemberMembership] =
    useState<Membership | null>(null);
  const [callCenterNumber, setCallCenterNumber] = useState<string | null>("");
  const [membershipChangeRequest, setMembershipChangeRequest] =
    useState<MembershipChangeRequest | null>(null);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
  const { isPAYGProduct } = useGetProductType(mainMemberMembership);

  useEffect(() => {
    const mainMemberMembership = findMembershipByMemberIdentifier(
      membershipIdentifier || "",
      memberships
    );
    setMainMemberMembership(mainMemberMembership);

    if (mainMemberMembership) {
      setCallCenterNumber(
        getMembershipCallCentreServicePhoneChannel(mainMemberMembership)
          ?.value || null
      );
    }
  }, [memberships, membershipIdentifier]);

  function onOptionsButtonClick() {
    setIsOptionsModalOpen(true);
  }

  function onAddDependentButtonClick() {
    if (isFeatureEnabled(FeatureToggleIdentifier.ENABLE_ADD_DEPENDANTS)) {
      navigate(`/home/memberships/${membershipIdentifier}/add-people`);
    } else {
      setMembershipChangeRequest({
        type: MembershipChangeRequestFormType.ADD_DEPENDENT_FORM,
        formData: {
          membershipIdentifier: membershipIdentifier,
        },
      });
    }
  }

  function shouldShowAddPeopleButton() {
    if (isFeatureEnabled(FeatureToggleIdentifier.ENABLE_ADD_DEPENDANTS)) {
      const activeDependentsCount =
        mainMemberMembership?.members.filter(
          (member) =>
            member.membershipDetails.type !== MembershipType.MAIN_MEMBER &&
            member.membershipDetails.status === MembershipStatus.ACTIVE
        ).length || 0;

      return (
        activeDependentsCount < MAX_ALLOWED_DEPENDENTS &&
        mainMemberMembership?.productDetails?.allowDependents &&
        isPAYGProduct
      );
    } else {
      return mainMemberMembership?.productDetails?.allowDependents;
    }
  }

  const sortedDependents = mainMemberMembership?.members.sort((a) =>
    a.membershipDetails.type === MembershipType.MAIN_MEMBER ? -1 : 0
  );

  const isDependentsListShown =
    sortedDependents &&
    (mainMemberMembership?.productDetails?.allowDependents ||
      sortedDependents.length > 0);

  return (
    <>
      <Panel>
        {isMembershipsLoading ? (
          <Stack alignItems="center">
            <LoadingSpinner />
          </Stack>
        ) : (
          <Stack spacing={2}>
            {!mainMemberMembership || membershipsFetchError ? (
              <Typography variant="h4" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            ) : (
              <>
                <Stack>
                  <MembershipCard membership={mainMemberMembership} />
                </Stack>

                {callCenterNumber && (
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Typography
                      variant="body2"
                      color={theme.palette.neutral[500]}
                    >
                      {t("MembershipContactDetails.label")}
                    </Typography>
                    <Typography variant="body2" fontWeight={600}>
                      {callCenterNumber}
                    </Typography>
                  </Stack>
                )}

                {isDependentsListShown && (
                  <>
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={onOptionsButtonClick}
                    >
                      {t("MembershipDetailPanel.optionsButton")}
                    </Button>

                    <Divider sx={{ mx: 2 }} />
                    <MembershipDetailDependentsList
                      dependents={sortedDependents}
                    />
                  </>
                )}

                {shouldShowAddPeopleButton() === true && (
                  <>
                    {sortedDependents && sortedDependents?.length > 0 && (
                      <Divider sx={{ mx: 2 }} />
                    )}

                    <Button
                      size="small"
                      fullWidth
                      startIcon={<IconLoader icon="PlusIcon" />}
                      onClick={onAddDependentButtonClick}
                    >
                      {t("MembershipDetailPanel.addDependentsButton")}
                    </Button>

                    {isFeatureEnabled(
                      FeatureToggleIdentifier.ENABLE_ADD_DEPENDANTS
                    ) && (
                      <Typography
                        color="neutral.500"
                        textAlign="center"
                        variant="body2"
                      >
                        <Trans
                          i18nKey="MembershipDetailPanel.maxAllowedDependentsLabel"
                          values={{
                            count: MAX_ALLOWED_DEPENDENTS,
                            maxDependentAge: MAX_DEPENDENT_AGE,
                          }}
                        />
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        )}
      </Panel>

      <MembershipOptionsModal
        isOpen={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
        membership={mainMemberMembership}
      />

      <SlideUpDialog
        isOpen={
          membershipChangeRequest?.type ===
          MembershipChangeRequestFormType.ADD_DEPENDENT_FORM
        }
        onClose={() => setMembershipChangeRequest(null)}
        label={t("MembershipManagementAddDependentForm.title")}
      >
        <MembershipManagementChangeRequestForm
          membershipIdentifier={membershipIdentifier}
          onCancel={() => setMembershipChangeRequest(null)}
          onClose={() => setMembershipChangeRequest(null)}
          membershipActionType={MembershipChangeRequestActionType.ADD_DEPENDENT}
          Icon={
            <IconLoader
              icon="GroupOfFacesIcon"
              sx={{ fontSize: (theme) => theme.spacing(10) }}
            />
          }
        />
      </SlideUpDialog>
    </>
  );
}
