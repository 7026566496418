import { Button, Stack, Typography } from "@mui/material";
import FullscreenBackground from "../FullscreenBackground";
import { useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";
import { useNavigate } from "react-router";
import theme from "../../theme";

export default function PaymentError() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onBackHomeButtonClick() {
    navigate("/home");
  }

  return (
    <>
      <Stack
        justifyContent="flex-end"
        color="primary.contrastText"
        spacing={4}
        sx={{
          height: "100%",
        }}
        p={2}
      >
        <IconLoader
          icon="TrolleyWithFilledCrossIcon"
          color={theme.palette.neutral[50] as any}
          sx={{
            fontSize: convertPxToRem(150),
            alignSelf: "center",
          }}
        />

        <Stack textAlign="center" spacing={4} py={4}>
          <Typography variant="h1" color="inherit">
            {t("common.somethingWentWrong")}
          </Typography>
          <Stack spacing={2}>
            {(
              t<any, any, string[]>(`PaymentError.subTitle`, {
                returnObjects: true,
              }) as []
            ).map((text: string, index: number) => (
              <Typography
                variant="body1"
                color="inherit"
                key={String(text) + String(index)}
              >
                {text}
              </Typography>
            ))}
          </Stack>
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <Button onClick={onBackHomeButtonClick} color="info" fullWidth>
            {t("common.backHomeButton")}
          </Button>
        </Stack>
      </Stack>
      <FullscreenBackground color="primary.main" />
    </>
  );
}
