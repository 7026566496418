import { useState, SyntheticEvent, useEffect } from "react";
import { Stack, Typography, Tabs, Tab, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
} from "../../theme";
import BottomNavigation from "../BottomNavigation";
import MembershipPanel from "../MembershipPanel";
import ProductBenefitsPanel from "../ProductBenefitsPanel";
import { useLocation, useNavigate } from "react-router-dom";

const MY_BENEFITS_ROUTES = ["benefits", "membership"];

export default function MyBenefits() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const matchedLocation = location.pathname.split("/");

  const [selectedTab, setSelectedTab] = useState<string>(
    getMatchedLocation() || MY_BENEFITS_ROUTES[0]
  );

  function getMatchedLocation(): string {
    return matchedLocation[matchedLocation.length - 1];
  }

  function isMatchedLocationMyBenefitsRoute(): boolean {
    return MY_BENEFITS_ROUTES.includes(getMatchedLocation());
  }

  useEffect(() => {
    if (getMatchedLocation() && isMatchedLocationMyBenefitsRoute()) {
      setSelectedTab(getMatchedLocation());
    }
  }, [matchedLocation]);

  const onTabChange = (_event: SyntheticEvent, newValue: string) => {
    navigate("/home/my-benefits/" + newValue);
  };

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
        ...pageWithBottomNavigationStyling,
      }}
    >
      <Typography
        component="h1"
        variant="h2"
        sx={{
          mb: 1,
        }}
      >
        {t("MyBenefits.title")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 4,
        }}
      >
        {t("MyBenefits.subtitle")}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label={t("MyBenefits.tabNavigation.label")}
          sx={{
            mx: defaultFullscreenPageStyling.px * -1,
            "& .MuiTabs-flexContainer::after": {
              content: '" "',
              minWidth: (theme) =>
                theme.spacing(defaultFullscreenPageStyling.px),
              position: "relative",
            },
          }}
        >
          <Tab
            value={MY_BENEFITS_ROUTES[0]}
            label={t("MyBenefits.tabNavigation.myBenefitsLink")}
            sx={{
              ml: defaultFullscreenPageStyling.px,
            }}
          />
          <Tab
            value={MY_BENEFITS_ROUTES[1]}
            label={t("MyBenefits.tabNavigation.myMembershipLink")}
          />
        </Tabs>
      </Box>

      {selectedTab === MY_BENEFITS_ROUTES[0] && <ProductBenefitsPanel />}
      {selectedTab === MY_BENEFITS_ROUTES[1] && <MembershipPanel />}

      <BottomNavigation />
    </Stack>
  );
}
