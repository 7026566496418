import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Typography, Stack, Button } from "@mui/material";
import theme from "../../theme";
import { useEffect, useMemo, useState } from "react";
import {
  MedicalServiceProviderType,
  Membership,
  ProductAdministratorSupportedServiceChannelAvailability,
  getMembershipCallCentreServicePhoneChannel,
  getMembershipCounsellingAssistanceServicePhoneChannel,
  getMembershipMedicalAssistanceServiceWhatsAppChannel,
} from "../../services/core-api-adapter";
import useSeekMedicalAssistance from "../../hooks/useSeekMedicalAssistance";
import { trackEvent } from "../../services/analytics-adapter";
import ServiceAvailabilityList from "../ServiceAvailabilityList";

interface GetMedicalAssistanceModalContentProps {
  membership: Membership;
}

export default function GetMedicalAssistanceModalContent({
  membership,
}: GetMedicalAssistanceModalContentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    showMedicalAssistanceProviderButton,
    setShowMedicalAssistanceProviderButton,
  ] = useState(false);
  const [showGenericError, setShowGenericError] = useState<boolean>(false);

  const [counsellingPhoneNumber, setCounsellingPhoneNumber] = useState("");

  const { isGenericError, isAssistanceRequestInProgress } =
    useSeekMedicalAssistance();

  const [memberMedicalAssistanceProvider, setMemberMedicalAssistanceProvider] =
    useState("");

  useEffect(() => {
    const counsellingPhoneNumber =
      getMembershipCounsellingAssistanceServicePhoneChannel(membership)
        ?.value || "";

    const medicalAssistancePhoneNumber =
      getMembershipMedicalAssistanceServiceWhatsAppChannel(membership)?.value ||
      "";

    const medicalServiceProvider = getMedicalAssistanceProvider(membership);

    setMemberMedicalAssistanceProvider(medicalServiceProvider);
    setShowMedicalAssistanceProviderButton(
      Boolean(medicalServiceProvider && medicalAssistancePhoneNumber)
    );

    setCounsellingPhoneNumber(counsellingPhoneNumber);
  }, [membership]);

  useEffect(() => {
    setShowGenericError(isGenericError);
  }, [isAssistanceRequestInProgress, isGenericError]);

  function onGetMedicalAssistanceButtonClick() {
    navigate(`/home/get-medical-assistance`);
  }

  function getMedicalAssistanceProvider(membership: Membership) {
    return (
      membership?.productDetails?.medicalServiceProvider ||
      MedicalServiceProviderType.WHATSAPP
    );
  }

  function medicalAssistanceButtonText() {
    switch (memberMedicalAssistanceProvider) {
      case MedicalServiceProviderType.WHATSAPP:
        return t("GetMedicalAssistanceModalContent.nurse.button");
      case MedicalServiceProviderType.CALL_ME_BACK:
        return t("common.continueButton");
    }
  }

  function medicalAssistanceOperatingHours(membership: Membership) {
    let serviceAvailability: ProductAdministratorSupportedServiceChannelAvailability[] =
      [];

    const medicalServiceProvider = getMedicalAssistanceProvider(membership);

    if (medicalServiceProvider === MedicalServiceProviderType.WHATSAPP) {
      serviceAvailability =
        getMembershipMedicalAssistanceServiceWhatsAppChannel(membership)
          ?.availability || [];
    } else if (
      medicalServiceProvider === MedicalServiceProviderType.CALL_ME_BACK
    ) {
      serviceAvailability =
        getMembershipCallCentreServicePhoneChannel(membership)?.availability ||
        [];
    }

    return serviceAvailability;
  }

  function onChatWithACounsellorButtonClick() {
    trackEvent({
      event: "action.getMedicalAssistanceTypeSelected",
      "getMedicalAssistance.type": "chat with a counsellor",
    });
  }

  const serviceAvailabilityOperatingTimes = useMemo(
    () => medicalAssistanceOperatingHours(membership),
    [membership]
  );

  return (
    <Stack textAlign={"center"}>
      {!isAssistanceRequestInProgress && !showGenericError && (
        <>
          <Stack spacing={2}>
            <Typography
              sx={{ fontWeight: "700" }}
              color={theme.palette.neutral[700]}
            >
              {t("GetMedicalAssistanceModalContent.nurse.title")}
            </Typography>
            {showMedicalAssistanceProviderButton && (
              <Button onClick={onGetMedicalAssistanceButtonClick}>
                {medicalAssistanceButtonText()}
              </Button>
            )}
            {serviceAvailabilityOperatingTimes?.length > 0 && (
              <Stack pt={1} px={2} spacing={1}>
                <Typography
                  sx={{ color: (theme) => theme.palette.grey[500] }}
                  textAlign="center"
                  variant="body2"
                >
                  {t("ServiceAvailabilityList.title")}
                </Typography>

                <ServiceAvailabilityList
                  data={serviceAvailabilityOperatingTimes}
                />
              </Stack>
            )}

            {counsellingPhoneNumber &&
              memberMedicalAssistanceProvider ===
                MedicalServiceProviderType.WHATSAPP && (
                <>
                  <Stack pt={2} spacing={2}>
                    <Typography color={theme.palette.neutral[700]}>
                      {t("GetMedicalAssistanceModalContent.counsellor.title")}
                    </Typography>
                    <Button
                      variant="outlined"
                      component="a"
                      onClick={onChatWithACounsellorButtonClick}
                      href={`tel:${counsellingPhoneNumber}`}
                      target="_blank"
                    >
                      {t("GetMedicalAssistanceModalContent.counsellor.button", {
                        phoneNumber: counsellingPhoneNumber,
                      })}
                    </Button>

                    <Typography
                      sx={{ color: (theme) => theme.palette.grey[500] }}
                      variant="body2"
                    >
                      {t(
                        "GetMedicalAssistanceModalContent.counsellor.availability"
                      )}
                    </Typography>
                  </Stack>
                </>
              )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
