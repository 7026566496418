import { Stack, Typography, Button, Link } from "@mui/material";
import { t } from "i18next";
import { Trans, useTranslation } from "react-i18next";
import IconLoader from "../IconLoader";
import { convertPxToRem } from "../../utils";
import ChatBotOptionSelectionModal from "./components/ChatBotOptionSelectionModal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  ChatBotOption,
  generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage,
} from "../../services/core-api-adapter";
import { trackEvent } from "../../services/analytics-adapter";
import FullScreenIframeDialog from "../FullScreenIframeDialog";
import DefaultDialog from "../DefaultDialog";

interface ChatBotDisclaimerModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function ChatBotDisclaimerModal({
  isOpen,
  onClose,
}: ChatBotDisclaimerModalProps) {
  const { t } = useTranslation();

  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "Chat bot screen",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }
  return (
    <DefaultDialog
      label={t("ChatBotPage.disclaimer.title")}
      isOpen={isOpen}
      onClose={onClose}
      fullWidth={true}
    >
      <Stack textAlign="center" spacing={2}>
        <Typography variant="h3" fontWeight={600}>
          {t("ChatBotPage.disclaimer.title")}
        </Typography>

        <Typography variant="body2" color="inherit">
          {t("ChatBotPage.disclaimer.body")}
        </Typography>

        <Typography variant="body2" color="inherit">
          <Trans i18nKey="ChatBotPage.disclaimer.termsAndConditions">
            <Link
              sx={{ color: "primary", fontWeight: 900 }}
              href="/"
              onClick={onTermsLinkClick}
            ></Link>
          </Trans>
        </Typography>
      </Stack>

      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
    </DefaultDialog>
  );
}

export default function ChatBot() {
  const navigate = useNavigate();
  const { chatBotType } = useParams();
  const [
    isChatBotOptionSelectionModalOpen,
    setIsChatBotOptionSelectionModalOpen,
  ] = useState(false);

  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);

  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  useEffect(() => {
    if (chatBotType && !(chatBotType in ChatBotOption)) {
      navigate("/home");
    }
  }, [chatBotType]);

  function onViewDisclaimerClick() {
    setIsDisclaimerModalOpen(true);
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }

  function onContinueButtonClick() {
    trackEvent({
      event: "action.sweetLifeContinueButtonClicked",
      source: "Chat bot screen",
    });
    setIsChatBotOptionSelectionModalOpen(true);
  }

  function onBackButtonClick() {
    navigate(-1);
  }

  return (
    <>
      <Stack
        spacing={4}
        p={2}
        sx={{
          height: "100%",
          overflowX: "hidden",
        }}
      >
        <IconLoader
          icon="ChatBubbleWithSweetLifeLogoIcon"
          sx={{ fontSize: convertPxToRem(80), alignSelf: "center" }}
        />

        <Stack spacing={4} alignItems="center">
          <Typography
            variant="h1"
            fontWeight={600}
            fontSize={convertPxToRem(26)}
          >
            {t("ChatBotPage.title")}
          </Typography>

          <Typography
            component="h2"
            variant="body1"
            textAlign="center"
            fontWeight="bold"
            color="neutral.main"
          >
            {t(`ChatBotPage.${chatBotType}.subtitle` as any)}
          </Typography>

          <Typography
            component="h2"
            variant="body1"
            textAlign="center"
            fontWeight="bold"
            color="neutral.main"
          >
            {t("ChatBotPage.body")}
          </Typography>

          <Button onClick={onViewDisclaimerClick} variant="text">
            {t("common.viewDisclaimer")}
          </Button>
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            onClick={onBackButtonClick}
          >
            {t("common.backButton")}
          </Button>
          <Button color="primary" fullWidth onClick={onContinueButtonClick}>
            {t("common.continueButton")}
          </Button>
        </Stack>
      </Stack>
      <ChatBotOptionSelectionModal
        selectedOption={chatBotType as ChatBotOption}
        isOpen={isChatBotOptionSelectionModalOpen}
        onClose={() => setIsChatBotOptionSelectionModalOpen(false)}
      />
      <FullScreenIframeDialog
        title={t(
          "CollectTermsAcceptance.termsAndConditions.TERMS_AND_CONDITIONS_PLATFORM.documentTitle"
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage()}
      />
      <ChatBotDisclaimerModal
        isOpen={isDisclaimerModalOpen}
        onClose={() => setIsDisclaimerModalOpen(false)}
      />
    </>
  );
}
