import FaceScanBreakout from ".";
import { Stack } from "@mui/material";

export default function FaceScanBreakoutExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <FaceScanBreakout provider="Intercare" onBack={() => {}} />
    </Stack>
  );
}
