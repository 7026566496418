import React, { useState } from "react";
import { Button, InputLabel, Stack, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styleInputDefault } from "../../theme";

interface Props {
  type: string;
  onChange?: Function;
  hideInput?: Function;
}

export default function MedicalConditionInput({
  onChange,
  type,
  hideInput,
}: Props) {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");

  function onAdd() {
    onChange && onChange(inputValue);
    hideInput && hideInput(false);
    setInputValue("");
  }

  function onClear() {
    hideInput && hideInput(false);
    setInputValue("");
  }

  return (
    <Stack
      role="form"
      aria-label={t(`${type}.addNewFormLabel` as any)}
      flexGrow={2}
      spacing={1}
    >
      <InputLabel>{t(`${type}.inputLabel` as any)}</InputLabel>
      <TextField
        onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
          setInputValue(event.target.value)
        }
        value={inputValue}
        inputProps={{
          autoComplete: "off",
          inputMode: "text",
          "aria-label": t(`${type}.inputLabel` as any),
        }}
        size="small"
        placeholder={t(`MedicalConditionList.placeholder`)}
        sx={{
          input: {
            ...styleInputDefault,
          },
        }}
      />
      <Stack flexDirection="row" gap={1}>
        <Button
          variant="outlined"
          fullWidth
          size="small"
          color="neutral"
          onClick={onClear}
        >
          {t(`common.cancelButton`)}
        </Button>
        <Button
          variant="contained"
          disabled={!inputValue}
          fullWidth
          size="small"
          color="neutral"
          onClick={onAdd}
        >
          {t(`MedicalConditionList.addLabel`)}
        </Button>
      </Stack>
    </Stack>
  );
}
