import { Stack, Typography } from "@mui/material";
import MarketplaceDashboardCard from "../MarketplaceDashboardCard";
import {
  ProductPremiumInterval,
  Product,
} from "../../services/core-api-adapter";
import { useTranslation } from "react-i18next";

const products = [
  {
    productPremiums: [
      {
        id: "5e46bcf1-d52a-4897-ac47-41a36a984b03",
        memberType: "MAIN_MEMBER",
        premiumFormatted: "FREE",
      },
    ],
  },
  {
    productPremiums: [
      {
        id: "5e46bcf1-d52a-4897-ac47-41a36a984b03",
        memberType: "MAIN_MEMBER",
        premiumFormatted: "R99.00",
        interval: ProductPremiumInterval.PER_MONTH,
      },
    ],
  },
  {
    productPremiums: [
      {
        id: "a157c838-cff3-4129-8278-ab1db4a86b6a",
        memberType: "MAIN_MEMBER",
        premiumFormatted: "R55.00",
        interval: ProductPremiumInterval.PER_MONTH,
      },
    ],
  },
];

const productsUganda = [
  {
    productPremiums: [
      {
        id: "a157c838-cff3-4129-8278-ab1db4a86b6a",
        memberType: "MAIN_MEMBER",
        premiumFormatted: "UGX988,896",
        interval: ProductPremiumInterval.PER_ANNUM,
      },
    ],
  },
  {
    productPremiums: [
      {
        id: "a157c838-cff3-4129-8278-ab1db4a86b6a",
        memberType: "MAIN_MEMBER",
        premiumFormatted: "UGX988,896",
        interval: ProductPremiumInterval.PER_ANNUM,
      },
    ],
  },
];

export default function MarketplaceDashboardPanelExamples() {
  const { t } = useTranslation();

  return (
    <>
      <Stack p={2} spacing={2}>
        <Typography variant="h4">
          {t("MarketplaceDashboardPanel.title")}
        </Typography>

        <MarketplaceDashboardCard products={products as Product[]} />
      </Stack>
      <Stack p={2} spacing={2}>
        <Typography variant="h4">
          {t("MarketplaceDashboardPanel.title")} Uganda
        </Typography>

        <MarketplaceDashboardCard products={productsUganda as Product[]} />
      </Stack>
    </>
  );
}
