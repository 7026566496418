import { useState, SyntheticEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGlobalStore } from "../../store";
import {
  defaultFullscreenPageStyling,
  pageWithBottomNavigationStyling,
} from "../../theme";
import BottomNavigation from "../BottomNavigation";
import MyContactDetails from "../MyContactDetails";
import HealthProfile from "../HealthProfile";
import HealthNudge from "../HealthNudge";
import HealthRecordContainer from "../HealthRecordContainer";
import LoadingSpinner from "../LoadingSpinner";
import useGetHealthProfile from "../../hooks/useGetHealthProfile";
import { getMembershipConfig } from "../../services/core-api-adapter";
import useGetMemberships from "../../hooks/useGetMemberships";
import { trackEvent } from "../../services/analytics-adapter";

const myHealthRoutes = ["health-profile", "health-record", "contact-details"];

export default function MyHealth() {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { healthProfile, isHealthProfileLoading } = useGetHealthProfile();
  const { firstMembership, isMembershipsLoading } = useGetMemberships();

  const matchedLocation = location.pathname.split("/");

  const [selectedTab, setSelectedTab] = useState<string>(
    getMatchedLocation() || myHealthRoutes[0]
  );

  function getMatchedLocation(): string {
    return matchedLocation[matchedLocation.length - 1];
  }

  function isMatchedLocationMyHealthRoute(): boolean {
    return myHealthRoutes.includes(getMatchedLocation());
  }

  function onTabChange(_event: SyntheticEvent, newTab: string) {
    const transformedTabName = newTab
      .split("-")
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join("");

    trackEvent({
      event: `action.myHealthTabClicked`,
      "myHealthTabClicked.name": transformedTabName,
      source: "My health",
    });

    navigate("/home/my-health/" + newTab);
  }

  function isHealthProfilePercentageComplete() {
    return healthProfile && Number(healthProfile?.percentageComplete) > 0;
  }

  useEffect(() => {
    if (getMatchedLocation() && isMatchedLocationMyHealthRoute()) {
      if (
        getMatchedLocation() === "health-record" &&
        !getMembershipConfig(firstMembership)?.isHealthRecordSupported
      ) {
        navigate("/home");
      }
      setSelectedTab(getMatchedLocation());
    }
  }, [matchedLocation]);

  return (
    <Stack
      sx={{
        ...defaultFullscreenPageStyling,
        ...pageWithBottomNavigationStyling,
      }}
    >
      <Typography
        component="h1"
        variant="h2"
        sx={{
          mb: 1,
        }}
      >
        {t("MyHealth.title", { name: state.currentUser.firstName })}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          mb: 4,
        }}
      >
        {t("MyHealth.subtitle")}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label={t("MyHealth.tabNavigation.label")}
          textColor="primary"
          sx={{
            mx: defaultFullscreenPageStyling.px * -1,
            "& .MuiTabs-flexContainer::after": {
              content: '" "',
              minWidth: (theme) =>
                theme.spacing(defaultFullscreenPageStyling.px),
              position: "relative",
            },
          }}
        >
          <Tab
            value={myHealthRoutes[0]}
            label={t("MyHealth.tabNavigation.myHealthProfileLink")}
            sx={{
              ml: defaultFullscreenPageStyling.px,
            }}
          />
          {firstMembership &&
            !isMembershipsLoading &&
            getMembershipConfig(firstMembership)?.isHealthRecordSupported && (
              <Tab
                value={myHealthRoutes[1]}
                label={t("MyHealth.tabNavigation.myHealthRecordLink")}
              />
            )}
          <Tab
            value={myHealthRoutes[2]}
            label={t("MyHealth.tabNavigation.myContactDetailsLink")}
          />
        </Tabs>
      </Box>

      {selectedTab === myHealthRoutes[0] && (
        <Stack spacing={2}>
          {isHealthProfileLoading === true ? (
            <Stack alignItems="center">
              <LoadingSpinner />
            </Stack>
          ) : (
            <>
              {!isHealthProfilePercentageComplete() ? (
                <HealthNudge
                  healthScore={Number(healthProfile?.healthScoreValue) || 0}
                  completionPercentage={healthProfile?.percentageComplete || 0}
                  callToActionButtonColour="primary"
                />
              ) : (
                <>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography component="h2" variant="h3">
                      {t("MyHealth.tabNavigation.myHealthProfileLink")}
                    </Typography>
                  </Stack>
                  <HealthProfile healthProfile={healthProfile} />
                </>
              )}
            </>
          )}
        </Stack>
      )}

      {selectedTab === myHealthRoutes[1] && <HealthRecordContainer />}
      {selectedTab === myHealthRoutes[2] && <MyContactDetails />}

      <BottomNavigation />
    </Stack>
  );
}
