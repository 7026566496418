import React, { useEffect, useState } from "react";
import { useMachine } from "@xstate/react";
import {
  questionnaireFlowStateMachine,
  eventNames as stateMachineEventNames,
} from "./machine";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Button, Fade, Stack } from "@mui/material";
import { useGlobalStore } from "../../store";
import QuestionnaireFlowStart from "../QuestionnaireFlowStart";
import HealthProfileQuestionnaireComplete from "../HealthProfileQuestionnaireComplete";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import FlowHeader from "../FlowHeader";
import { QuestionnaireCurrentSuccessResponseData } from "../../services/core-api-adapter";
import { baseButtonStyle } from "../../theme";
import QuestionTypeBloodType from "../QuestionTypeBloodType";
import QuestionTypeStatus from "../QuestionTypeStatus";
import QuestionTypeDateOfBirth from "../QuestionTypeDateOfBirth";
import QuestionTypeHeight from "../QuestionTypeHeight";
import QuestionTypeMedicalConditionList from "../QuestionTypeMedicalConditionList";
import QuestionTypeSexAtBirth from "../QuestionTypeSexAtBirth";
import QuestionTypeSmokingFrequency from "../QuestionTypeSmokingFrequency";
import QuestionTypeSmokingStatus from "../QuestionTypeSmokingStatus";
import QuestionTypeWeight from "../QuestionTypeWeight";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import WhyAreWeAskingYouThisModal from "../WhyAreWeAskingYouThisModal";
import { QuestionStatusType } from "../MedicalConditionList";
import IconLoader from "../IconLoader";
import useGetHealthProfile from "../../hooks/useGetHealthProfile";
import { convertStringToJSON, isObjectEmpty } from "../../utils";

const questionTypesMapping: any = {
  SexAtBirth: QuestionTypeSexAtBirth,
  DateOfBirth: QuestionTypeDateOfBirth,
  Height: QuestionTypeHeight,
  Weight: QuestionTypeWeight,
  BloodType: QuestionTypeBloodType,
  ConditionsStatus: QuestionTypeStatus,
  ConditionsList: QuestionTypeMedicalConditionList,
  AllergiesStatus: QuestionTypeStatus,
  AllergiesList: QuestionTypeMedicalConditionList,
  SmokingStatus: QuestionTypeSmokingStatus,
  SmokingFrequency: QuestionTypeSmokingFrequency,
};

const questionTypesHistory: string[] = [];

export default function QuestionnaireFlow() {
  const { t } = useTranslation();
  const { state } = useGlobalStore();

  const [stateMachineState, dispatchStateMachineEvent] = useMachine(
    questionnaireFlowStateMachine
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { healthProfile } = useGetHealthProfile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stateMachineState]);

  function onModalOpen() {
    setIsModalOpen(true);
  }

  function onModalClose() {
    setIsModalOpen(false);
  }

  function getQuestionTypeComponentData() {
    let data =
      stateMachineState.context.answerPayload ||
      convertStringToJSON(
        healthProfile?.items.find(
          (healthProfileItem) =>
            healthProfileItem.questionType ===
            stateMachineState.context.currentQuestion.questionType
        )?.data || ""
      );

    switch (
      stateMachineState.context.answerPayload &&
      stateMachineState.context.currentQuestion.questionType
    ) {
      case "SexAtBirth":
      case "ConditionsStatus":
      case "AllergiesStatus":
      case "SmokingStatus":
        data = stateMachineState.context.answerPayload?.value;
        break;
    }

    if (!data || isObjectEmpty(data)) {
      return null;
    }
    return data;
  }

  useEffect(() => {
    let componentData: any = convertStringToJSON(
      healthProfile?.items.find(
        (healthProfileItem) =>
          healthProfileItem.questionType ===
          stateMachineState.context?.currentQuestion?.questionType
      )?.data || ""
    );

    const componentDataHasValueAndNotAnEmptyObject =
      componentData && !isObjectEmpty(componentData);

    const isNewQuestionType = !questionTypesHistory.includes(
      stateMachineState.context?.currentQuestion?.questionType
    );

    if (
      componentDataHasValueAndNotAnEmptyObject &&
      !componentData?.hasOwnProperty("value")
    ) {
      componentData = { value: componentData };
    }

    if (componentDataHasValueAndNotAnEmptyObject && isNewQuestionType) {
      questionTypesHistory.push(
        stateMachineState.context?.currentQuestion?.questionType
      );
      dispatchStateMachineEvent({
        type: stateMachineEventNames.ANSWER_IS_VALID,
      });
      dispatchStateMachineEvent({
        type: stateMachineEventNames.ANSWER_COLLECTED,
        info: componentData,
      });
      dispatchStateMachineEvent({
        type: stateMachineEventNames.ANSWER_QUESTION_BUTTON_CLICKED,
      });
    }
  }, [healthProfile, stateMachineState.context]);

  function onAnswerChange(answer: any) {
    if (answer.isValid) {
      dispatchStateMachineEvent({
        type: stateMachineEventNames.ANSWER_IS_VALID,
      });
      dispatchStateMachineEvent({
        type: stateMachineEventNames.ANSWER_COLLECTED,
        info: answer.data,
      });
    } else {
      dispatchStateMachineEvent({
        type: stateMachineEventNames.ANSWER_IS_INVALID,
      });
    }
  }

  function getQuestionTypeComponentExtras(type: typeof questionTypesMapping) {
    if (type === "AllergiesList" || type === "AllergiesStatus") {
      return { type: QuestionStatusType.ALLERGIES };
    }
    if (type === "ConditionsList" || type === "ConditionsStatus") {
      return { type: QuestionStatusType.CONDITIONS };
    }
  }

  function renderQuestionTypeComponentFromQuestionData(
    question: QuestionnaireCurrentSuccessResponseData
  ) {
    const questionTypeNameNormalisedForLookup = question?.questionType || "";
    if (
      typeof questionTypesMapping[questionTypeNameNormalisedForLookup] ===
      "undefined"
    ) {
      dispatchStateMachineEvent({
        type: stateMachineEventNames.UNSUPPORTED_QUESTION_TYPE,
      });
      return null;
    }
    return (
      <>
        <FaceIconBrandLarge />

        {React.createElement(
          questionTypesMapping[questionTypeNameNormalisedForLookup],
          {
            onChange: onAnswerChange,
            data: getQuestionTypeComponentData(),
            ...getQuestionTypeComponentExtras(
              questionTypeNameNormalisedForLookup
            ),
          }
        )}

        <Button variant="text" onClick={onModalOpen} size="small">
          {t("WhyAreWeAskingYouThisModal.title")}
        </Button>

        <WhyAreWeAskingYouThisModal
          extras={t(
            `QuestionType${questionTypeNameNormalisedForLookup}.modalContent` as any
          )}
          isOpen={isModalOpen}
          onClose={onModalClose}
        />

        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={() =>
              dispatchStateMachineEvent({
                type: stateMachineEventNames.SKIP_QUESTION_BUTTON_CLICKED,
              })
            }
            fullWidth
            sx={{ ...baseButtonStyle, px: 0 }}
          >
            {t("QuestionnaireFlow.skipQuestionButton")}
          </Button>
          <Button
            onClick={() =>
              dispatchStateMachineEvent({
                type: stateMachineEventNames.ANSWER_QUESTION_BUTTON_CLICKED,
              })
            }
            fullWidth
            disabled={!stateMachineState.context.isAnswerQuestionSubmitEnabled}
          >
            {t("QuestionnaireFlow.answerQuestionButton")}
          </Button>
        </Stack>
      </>
    );
  }

  return (
    <Stack
      spacing={2}
      justifyContent="space-between"
      sx={{ minHeight: "100%", overflowX: "hidden" }}
    >
      <Box>
        {stateMachineState.context.isFlowHeaderVisible && (
          <FlowHeader
            title={t("QuestionnaireFlow.title")}
            onBackButtonClick={() => {
              dispatchStateMachineEvent({
                type: stateMachineEventNames.GO_BACK,
              });
            }}
            value={
              stateMachineState.context.chosenQuestionnaireSummary
                .currentQuestionIndex
            }
            max={
              stateMachineState.context.chosenQuestionnaireSummary
                .totalQuestions
            }
            isBackButtonVisible={
              stateMachineState.context.isBackTransitionAllowed
            }
          />
        )}

        {stateMachineState.context.isCompleteLaterButtonVisible && (
          <Box textAlign="right" sx={{ mr: 1, mt: 1 }}>
            <Button
              variant="text"
              size="small"
              color="neutral"
              startIcon={<IconLoader icon="BookmarkIcon" />}
              onClick={() => {
                dispatchStateMachineEvent({
                  type: stateMachineEventNames.COMPLETE_LATER_BUTTON_CLICKED,
                });
              }}
            >
              {t("common.completeLaterButton")}
            </Button>
          </Box>
        )}
      </Box>

      <Box>
        {(stateMachineState.matches("start") ||
          stateMachineState.matches("skippingQuestion") ||
          stateMachineState.matches("fetchingQuestionnaireSummary") ||
          stateMachineState.matches("submittingAnswerToQuestion") ||
          stateMachineState.matches("completeLater")) && (
          <FullscreenLoadingIndicator isOpen={true} mainIconType="face" />
        )}

        {stateMachineState.matches("prompting") && (
          <Fade in={true}>
            <Box sx={{ p: 2 }}>
              <QuestionnaireFlowStart
                onStartButtonClick={() => {
                  dispatchStateMachineEvent({
                    type: stateMachineEventNames.GET_STARTED_BUTTON_CLICKED,
                  });
                }}
                onSkipButtonClick={() => {
                  dispatchStateMachineEvent({
                    type: stateMachineEventNames.COMPLETE_LATER_BUTTON_CLICKED,
                  });
                }}
              />
            </Box>
          </Fade>
        )}

        {stateMachineState.matches("collectingAnswer") && (
          <Fade in={true}>
            <Stack
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: "100%", p: 2 }}
            >
              {renderQuestionTypeComponentFromQuestionData(
                stateMachineState.context.currentQuestion
              )}
            </Stack>
          </Fade>
        )}

        {stateMachineState.matches("done") && (
          <Fade in={true}>
            <Box sx={{ height: "100%", p: 2 }}>
              <HealthProfileQuestionnaireComplete
                firstName={state.currentUser.firstName}
              />
            </Box>
          </Fade>
        )}

        {stateMachineState.matches("exit") && <Navigate to="/" />}
      </Box>
    </Stack>
  );
}
