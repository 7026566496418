import { Box, Stack } from "@mui/material";
import WatermarkIcon from ".";
import theme from "../../theme";

export default function WatermarkIconExamples() {
  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction={"row"}>
        <Box>
          <WatermarkIcon
            height={36}
            width={36}
            color={theme.palette.accent2.light}
            foreground="primary"
            Icon={"CheckInShieldIcon"}
          />
        </Box>
        <Box>
          <WatermarkIcon
            height={36}
            width={36}
            color="primary.main"
            foreground="primary"
            Icon={"HeadphonesIcon"}
          />
        </Box>
      </Stack>
      <Box>
        <WatermarkIcon
          height={55}
          width={55}
          color="accent1.main"
          foreground="info"
          Icon={"CheckmarkIcon"}
        />
      </Box>
      <Box>
        <WatermarkIcon
          height={90}
          width={90}
          color={theme.palette.accent3.light}
          foreground="info"
          Icon={"CrossIcon"}
        />
      </Box>
    </Stack>
  );
}
