import { Link, Stack, Typography } from "@mui/material";
import { FormEventHandler, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  trackEvent,
  trackUserInteraction,
} from "../../services/analytics-adapter";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";
import FullScreenIframeDialog from "../FullScreenIframeDialog";
import TermsResponseForm from "../TermsResponseForm";

interface CollectTermsProps {
  onFormSubmit: FormEventHandler<HTMLFormElement>;
  onTermsReject: () => void;
  termsAndConditionFileURLFromSuppliedData: string;
  termsTitle: string;
  termsMessage: any;
  termsBody?: string;
  data?: { Description: string };
  contractType?: string;
}

export default function CollectTerms(props: CollectTermsProps) {
  const { t } = useTranslation();
  const [isTermsAndConditionsDialogOpen, setIsTermsAndConditionsDialogOpen] =
    useState(false);

  function onTermsLinkClick(event: any) {
    event.preventDefault();
    setIsTermsAndConditionsDialogOpen(true);
    trackEvent({
      event: "action.termsAndConditionsLinkOpened",
      source: "memberRequiredActions",
    });

    trackUserInteraction({
      linkText: "T&Cs Screen | terms and conditions",
      linkIntent: "informational",
      linkScope: "button",
    });
  }

  function onTermsDialogClose() {
    setIsTermsAndConditionsDialogOpen(false);
  }

  return (
    <>
      <Stack spacing={4} justifyContent="flex-end" sx={{ pb: 2 }}>
        <Stack spacing={2}>
          <FaceIconBrandLarge />
          <Typography variant="h3">{props.termsTitle}</Typography>
          {props.termsBody && <Typography>{props.termsBody}</Typography>}
          <Typography>
            <Trans
              i18nKey={props.termsMessage}
              values={{
                productName: props?.data?.Description,
              }}
            >
              <Link href="/" onClick={onTermsLinkClick}></Link>
            </Trans>
          </Typography>
        </Stack>
        <TermsResponseForm
          onTermsReject={props.onTermsReject}
          onFormSubmit={props.onFormSubmit}
        />
      </Stack>

      <FullScreenIframeDialog
        title={t(
          `CollectTermsAcceptance.termsAndConditions.${props.contractType}.documentTitle` as any
        )}
        isOpen={isTermsAndConditionsDialogOpen}
        onClose={onTermsDialogClose}
        url={props.termsAndConditionFileURLFromSuppliedData}
      />
    </>
  );
}
