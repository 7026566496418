import { useState, MouseEvent, useEffect } from "react";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { baseToggleButtonStyle } from "../../theme";

interface OptionToggleProps {
  options: {
    name: string;
    value: string;
    disabled?: boolean;
  }[];
  onChange?: Function;
  value?: string[] | string;
  multiple?: boolean;
  preventEmptyValue?: boolean;
}

export default function OptionToggle({
  options,
  onChange,
  value,
  multiple = false,
  preventEmptyValue = false,
}: OptionToggleProps) {
  const [internalValue, setInternalValue] = useState<string[] | string | null>(
    value || null
  );

  useEffect(() => {
    let internalValueNormalisedToArray: string[] = [];

    if (Array.isArray(internalValue)) {
      internalValueNormalisedToArray = internalValue;
    } else if (typeof internalValue === "string") {
      internalValueNormalisedToArray = [internalValue];
    }

    onChange && onChange(internalValueNormalisedToArray);
  }, [internalValue]);

  const onToggleButtonGroupChange = (
    _event: MouseEvent<HTMLElement>,
    newValue: string[] | string | null
  ) => {
    if (preventEmptyValue) {
      if ((Array.isArray(newValue) && !newValue.length) || newValue === null) {
        return;
      }
    }

    setInternalValue(newValue);
  };

  return (
    <ToggleButtonGroup
      color="neutral"
      value={internalValue}
      exclusive={!multiple}
      onChange={onToggleButtonGroupChange}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",

        margin: (theme) => {
          return `${theme.spacing(-1)} 0 0 ${theme.spacing(-1)}`;
        },

        ".MuiButtonBase-root.MuiToggleButton-root, .MuiToggleButtonGroup-grouped.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected":
          {
            borderRadius: baseToggleButtonStyle.borderRadius,
            borderStyle: baseToggleButtonStyle.borderStyle,
            borderWidth: baseToggleButtonStyle.borderWidth,
            margin: (theme) => {
              return `${theme.spacing(1)} 0 0 ${theme.spacing(1)}`;
            },
          },
      }}
    >
      {options.map(function (option, index) {
        return (
          <ToggleButton
            key={String(option.name + index)}
            value={option.value}
            disabled={option.disabled}
          >
            {option.name}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}
