import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { submitMultipleContractAgreements } from "../../services/core-api-adapter";
import CollectTerms from "../CollectTerms";
import FullscreenLoadingIndicator from "../FullscreenLoadingIndicator";
import {
  AnalyticsEvent,
  trackEvent,
  trackFormEvent,
  trackUserInteraction,
} from "../../services/analytics-adapter";

interface CollectTermsAcceptanceContract {
  ContractType: string;
  Version: number;
  FileUri: string;
}

interface CollectTermsAcceptanceProps {
  onSubmit: Function;
  data: CollectTermsAcceptanceContract[];
}

function findTermsAndConditionsContractFromList(
  contracts: CollectTermsAcceptanceContract[]
): CollectTermsAcceptanceContract | null {
  return (
    contracts.find((contract: CollectTermsAcceptanceContract) => {
      return (
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_ZA" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_MZ" ||
        contract.ContractType === "TERMS_AND_CONDITIONS_PLATFORM_UG" ||
        contract.ContractType === "TERMS_AND_CONDITIONS"
      );
    }) || null
  );
}

export default function CollectTermsAcceptance(
  props: CollectTermsAcceptanceProps
) {
  const { t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const termsAndConditionContract = findTermsAndConditionsContractFromList(
    props.data
  );

  const termsAndConditionFileURLFromSuppliedData =
    termsAndConditionContract?.FileUri || "";

  useEffect(() => {
    trackFormEvent(AnalyticsEvent.FORM_START, {
      formName: "Collect terms and conditions",
    });
  }, []);

  function onFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsSubmittingForm(true);
    submitMultipleContractAgreements(
      props.data.map((contractAgreement) => {
        return {
          contractType: contractAgreement.ContractType,
          version: String(contractAgreement.Version),
        };
      })
    )
      .catch(() => setIsSubmittingForm(false))
      .then(() => {
        setIsSubmittingForm(false);
        onDone();
      });
  }

  function onTermsReject() {
    trackEvent({
      event: "action.termsAndConditionsRejected",
      source: "platformTermsAndConditions",
    });

    trackUserInteraction({
      linkText: "T&Cs screen | I don't agree",
      linkIntent: "confirmational",
      linkScope: "button",
    });
  }

  function onDone() {
    trackEvent({
      event: "action.termsAndConditionsAccepted",
      source: "platformTermsAndConditions",
    });

    trackFormEvent(AnalyticsEvent.FORM_COMPLETE, {
      formName: "Collect terms and conditions",
    });

    props.onSubmit();
  }

  return (
    <>
      {isSubmittingForm ? (
        <FullscreenLoadingIndicator />
      ) : (
        <CollectTerms
          onFormSubmit={onFormSubmit}
          onTermsReject={onTermsReject}
          termsTitle={t("CollectTermsAcceptance.title")}
          termsMessage="CollectTermsAcceptance.termsAndConditions.message"
          termsAndConditionFileURLFromSuppliedData={
            termsAndConditionFileURLFromSuppliedData
          }
          termsBody={t("CollectTermsAcceptance.body")}
          contractType={termsAndConditionContract?.ContractType}
        />
      )}
    </>
  );
}
