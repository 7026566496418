import { Stack, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";

interface QuestionnairePromptProps {
  onStartButtonClick: Function;
  onSkipButtonClick: Function;
}

export default function QuestionnairePrompt(props: QuestionnairePromptProps) {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="flex-end" spacing={2}>
      <FaceIconBrandLarge />

      <Typography variant="h3">{t("QuestionnairePrompt.title")}</Typography>
      {(
        t<any, any, string[]>("QuestionnairePrompt.body", {
          returnObjects: true,
        }) as []
      ).map((text: string, index: number) => (
        <Typography key={String(text + index)} variant="body1">
          {text}
        </Typography>
      ))}

      <Stack spacing={2} sx={{ pt: 2 }}>
        <Button
          onClick={() => {
            props.onStartButtonClick();
          }}
          fullWidth
        >
          {t("common.getStartedButton")}
        </Button>
        <Button
          onClick={() => {
            props.onSkipButtonClick();
          }}
          variant="text"
          fullWidth
        >
          {t("common.completeLaterButton")}
        </Button>
      </Stack>
    </Stack>
  );
}
