import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Message, MessageStatus } from "../../../../services/core-api-adapter";
import theme from "../../../../theme";
import {
  convertPxToRem,
  formatDateToReadableString,
  isDateYesterday,
} from "../../../../utils";
import IconLoader from "../../../IconLoader";
import MessageDetailTypeIcon from "../MessageDetailTypeIcon";

interface MessageProps {
  message: Message;
}

export default function MessageItem({ message }: MessageProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isMessageRead = message.notificationStatus === MessageStatus.READ;

  function onMessageItemClick() {
    navigate(`/home/messages/${message.id}`);
  }

  return (
    <>
      <Stack
        component="li"
        onClick={onMessageItemClick}
        aria-label={message.title}
        sx={{
          border: "none",
          cursor: "pointer",
          backgroundColor: "transparent",
        }}
        textAlign="left"
        px={2}
        py={4}
        spacing={1}
        display="flex"
        flexDirection="row"
        gap={2}
      >
        <Stack>
          <Box
            aria-label={t("Messages.MessageStatus.label")}
            data-read-status={
              isMessageRead
                ? t("Messages.MessageStatus.read.label")
                : t("Messages.MessageStatus.unread.label")
            }
            sx={{
              display: "flex",
              backgroundColor: !isMessageRead
                ? theme.palette.primary.main
                : "transparent",
              borderRadius: "50%",
              height: convertPxToRem(10),
              width: convertPxToRem(10),
              marginTop: convertPxToRem(8),
            }}
          />
        </Stack>

        <Stack
          flex={1}
          style={{
            marginTop: 0,
          }}
        >
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: "100%",
            }}
          >
            <Stack flexDirection="row" gap={1} alignItems="center">
              {message.detailType && (
                <MessageDetailTypeIcon detailType={message.detailType} />
              )}
              <Typography
                variant="h3"
                mt={0}
                sx={{
                  fontWeight: !isMessageRead ? 600 : 400,
                }}
              >
                {message.title}
              </Typography>
            </Stack>
            <IconLoader icon="ChevronNextIcon" />
          </Stack>
          <Typography variant="body1" color="neutral.light">
            {message.subTitle}
          </Typography>
          <Typography variant="body2" color="neutral.light">
            {isDateYesterday(new Date(message.date))
              ? t("Messages.MessageDate.Yesterday")
              : formatDateToReadableString(new Date(message.date))}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}
