import { ChangeEvent, useEffect, useId, useState } from "react";
import { InputLabel, Stack, Switch, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isValid as isValidEmailAddress } from "../../utils/validators/EmailAddress/EmailAddress";
import { isValid as isValidInternationalPhoneNumber } from "../../utils/validators/InternationalPhoneNumber/InternationalPhoneNumber";
import { getMember } from "../../services/core-api-adapter";
import { useGlobalStore } from "../../store";
interface CommunicationPreferencesInputsProps {
  onChange: Function;
  value?: CommunicationPreferencesItem[];
}

interface InternalCommunicationPreferencesItem {
  communicationType: string;
  validationFunction: Function;
  switchLabel: string;
  inputLabel: string;
  inputID: string;
  isActive: boolean;
  isValid: boolean;
  value: string;
}

export interface CommunicationPreferencesOutputItem {
  communicationType: string;
  communicationValue: string;
  isActive: boolean;
  isValid: boolean;
}

export interface CommunicationPreferencesItem {
  communicationType: string;
  communicationValue: string;
  isActive: boolean;
}

export default function CommunicationPreferencesInputs(
  props: CommunicationPreferencesInputsProps
) {
  const { t } = useTranslation();
  const { state } = useGlobalStore();

  const internalCommunicationPreferencesItems: InternalCommunicationPreferencesItem[] =
    [
      {
        communicationType: "Email",
        validationFunction: isValidEmailAddress,
        switchLabel: t("common.emailSwitchLabel"),
        inputLabel: t("common.emailAddressInputLabel"),
        inputID: useId(),
        isActive: state.currentUser.emailAddress ? true : false,
        isValid: false,
        value: state.currentUser.emailAddress || "",
      },
      {
        communicationType: "SMS",
        validationFunction: isValidInternationalPhoneNumber,
        switchLabel: t("common.smsSwitchLabel"),
        inputLabel: t("common.phoneNumberInputLabel"),
        inputID: useId(),
        isActive: state.currentUser.mobileNumber ? true : false,
        isValid: false,
        value: state.currentUser.mobileNumber || "",
      },
    ];

  const [internalValues, setInternalValues] = useState<
    InternalCommunicationPreferencesItem[]
  >([]);

  useEffect(() => {
    const propsValue = props.value;
    setInternalValues(
      mapSuppliedValuesToInternalValues(propsValue ? propsValue : [])
    );
    getMember().then((member: any) => {
      if (propsValue) {
        internalCommunicationPreferencesItems.map(
          (
            communicationPreferencesItem: InternalCommunicationPreferencesItem
          ) => {
            if (
              communicationPreferencesItem.communicationType === "Email" &&
              member.emailAddress
            ) {
              communicationPreferencesItem.value = member.emailAddress;
            }

            return communicationPreferencesItem;
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    props.onChange(mapInternalValueToOutputValue());
  }, [internalValues]);

  function mapSuppliedValuesToInternalValues(
    values: CommunicationPreferencesItem[]
  ): InternalCommunicationPreferencesItem[] {
    return internalCommunicationPreferencesItems.map(
      (
        internalCommunicationPreferencesItem: InternalCommunicationPreferencesItem
      ) => {
        const valueItem = values.find(
          (communicationPreferencesItem: CommunicationPreferencesItem) => {
            return (
              communicationPreferencesItem.communicationType ===
              internalCommunicationPreferencesItem.communicationType
            );
          }
        );
        if (valueItem) {
          internalCommunicationPreferencesItem.isActive = valueItem.isActive;
          internalCommunicationPreferencesItem.value =
            valueItem.communicationValue;
        }
        return internalCommunicationPreferencesItem;
      }
    );
  }

  function mapInternalValueToOutputValue(): CommunicationPreferencesOutputItem[] {
    return internalValues
      .map((internalValueItem: InternalCommunicationPreferencesItem) => {
        internalValueItem.isValid = internalValueItem.validationFunction(
          internalValueItem.value
        );
        return internalValueItem;
      })
      .map((internalValueItem: InternalCommunicationPreferencesItem) => {
        return {
          communicationType: internalValueItem.communicationType,
          communicationValue: internalValueItem.value,
          isActive: internalValueItem.isActive,
          isValid: internalValueItem.isValid,
        };
      });
  }

  function onSwitchChange(
    identifier: string,
    event: ChangeEvent<HTMLInputElement>
  ) {
    setInternalValues(
      internalValues.map(
        (internalValueItem: InternalCommunicationPreferencesItem) => {
          if (internalValueItem.communicationType === identifier) {
            internalValueItem.isActive = event.target.checked;
          }

          return internalValueItem;
        }
      )
    );
  }

  function normaliseStringToNumber(value: string): string {
    return value.replace(/[^\d+]/g, "");
  }

  function onInputChange(
    identifier: string,
    event: ChangeEvent<HTMLInputElement>
  ) {
    setInternalValues(
      internalValues.map(
        (internalValueItem: InternalCommunicationPreferencesItem) => {
          if (internalValueItem.communicationType === identifier) {
            if (internalValueItem.communicationType === "SMS") {
              internalValueItem.value = normaliseStringToNumber(
                event.target.value
              );
            } else {
              internalValueItem.value = event.target.value;
            }
          }

          return internalValueItem;
        }
      )
    );
  }

  return (
    <Stack spacing={2}>
      {internalValues.map(
        (
          communicationPreferencesItem: InternalCommunicationPreferencesItem
        ) => {
          return (
            <Stack key={communicationPreferencesItem.inputID}>
              <Stack
                component="label"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <span>{communicationPreferencesItem.switchLabel}</span>
                <Switch
                  checked={communicationPreferencesItem.isActive}
                  onChange={onSwitchChange.bind(
                    null,
                    communicationPreferencesItem.communicationType
                  )}
                />
              </Stack>
              {communicationPreferencesItem.isActive && (
                <Stack spacing={1}>
                  <InputLabel htmlFor={communicationPreferencesItem.inputID}>
                    {communicationPreferencesItem.inputLabel}
                  </InputLabel>
                  <TextField
                    id={communicationPreferencesItem.inputID}
                    value={communicationPreferencesItem.value}
                    onInput={onInputChange.bind(
                      null,
                      communicationPreferencesItem.communicationType
                    )}
                    size="small"
                    fullWidth
                  />
                </Stack>
              )}
            </Stack>
          );
        }
      )}
    </Stack>
  );
}
