import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FrequencyInput from "../FrequencyInput";

interface Props {
  data?: {
    value: number;
    interval: string;
  };
  onChange?: Function;
}

const initialState = {
  value: 0,
  interval: "",
};

export default function QuestionTypeSmokingFrequency(props: Props) {
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState(
    props.data || initialState
  );

  const options = [
    {
      name: t("common.intervals.IN_A_DAY"),
      value: "DAILY",
    },
    {
      name: t("common.intervals.IN_A_WEEK"),
      value: "WEEKLY",
    },
    {
      name: t("common.intervals.IN_A_MONTH"),
      value: "MONTHLY",
    },
  ];

  useEffect(() => {
    setInternalValue(props.data || initialState);
  }, [props.data]);

  useEffect(() => {
    props.onChange &&
      props.onChange({
        data: {
          value: Number.isInteger(internalValue.value)
            ? internalValue.value
            : null,
          interval: internalValue.interval || null,
        },
        isValid:
          Number.isInteger(internalValue.value) && internalValue.interval
            ? true
            : false,
      });
  }, [internalValue]);

  return (
    <>
      <Stack spacing={2} justifyContent="space-between">
        <Stack spacing={4}>
          <Typography variant="h3">
            {t("QuestionTypeSmokingFrequency.title")}
          </Typography>
          <FrequencyInput
            label={t("QuestionTypeSmokingFrequency.frequencyInputLabel")}
            onChange={(data: { value: number; interval: string }) =>
              setInternalValue(data)
            }
            options={options}
            initialValue={internalValue.value}
            selectedOption={internalValue.interval}
          />
        </Stack>
      </Stack>
    </>
  );
}
