import { useEffect } from "react";
import MyBenefits from "../../components/MyBenefits";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function MyBenefitsPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "My benefits:My benefits",
      pageSubSection1: "My benefits",
      pageSubSection2: "My benefits:My benefits",
      pageCategory: "My benefits",
    });
  }, []);

  return <MyBenefits />;
}
