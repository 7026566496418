import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FaceIconBrandLarge from "../../theme/icons/FaceIconBrandLarge";

interface CollectConsentCommonContentProps {
  titleTextKey: string;
  bodyTextKey: string;
}

export default function CollectConsentCommonContent(
  props: CollectConsentCommonContentProps
) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <FaceIconBrandLarge />
      <Typography variant="h3">{props.titleTextKey}</Typography>
      {(
        t<any, any, string[]>(props.bodyTextKey, {
          returnObjects: true,
        }) as []
      ).map((text: string, index: number) => (
        <Typography key={String(text + index)}>{text}</Typography>
      ))}
    </Stack>
  );
}
