import { Stack } from "@mui/material";
import PaymentError from ".";

export default function PaymentErrorExamples() {
  return (
    <Stack sx={{ height: "100vh" }}>
      <PaymentError />
    </Stack>
  );
}
