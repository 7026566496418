import { Stack, Typography } from "@mui/material";
import CircledBackButton from "../../components/CircledBackButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { defaultFullscreenPageStyling } from "../../theme";

export default function OpenSourceLicensesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onCloseButtonClick() {
    navigate(-1);
  }

  return (
    <>
      <Stack sx={{ minHeight: "100%" }}>
        <Stack
          spacing={2}
          sx={{
            px: defaultFullscreenPageStyling.px,
            py: defaultFullscreenPageStyling.py,
          }}
        >
          <CircledBackButton onClick={onCloseButtonClick} showLabel={true} />
          <Typography variant="h2">
            {t("AboutApp.sections.openSourceLicenses.title")}
          </Typography>
        </Stack>

        <iframe
          title={t("AboutApp.sections.openSourceLicenses.title")}
          src="/open-source-licenses.html"
          style={{
            border: "none",
            flexGrow: 1,
            height: "100%",
            width: "100%",
          }}
        ></iframe>
      </Stack>
    </>
  );
}
