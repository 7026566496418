import { Box, Stack } from "@mui/material";
import theme, { shadows } from "../../theme";
import IconLoader from "../IconLoader";

interface HealthRecordStateWrapperProps {
  children: React.ReactNode;
}

export default function HealthRecordStateWrapper({
  children,
}: HealthRecordStateWrapperProps) {
  return (
    <Stack
      borderRadius={2.5}
      overflow="hidden"
      spacing={3}
      sx={{
        background: theme.palette.background.paper,
        boxShadow: shadows.light,
      }}
    >
      <Box sx={{ height: theme.spacing(2), position: "relative" }}>
        <IconLoader
          icon="PatternSemiCircles"
          preserveAspectRatio="none"
          sx={{
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            color: "accent1.main",
          }}
        />
      </Box>
      <Stack alignItems="center">
        <Stack
          alignItems="center"
          justifyContent="center"
          justifySelf="center"
          bgcolor="accent1.100"
          sx={{
            borderRadius: "50%",
            width: 51,
            height: 51,
          }}
        >
          <IconLoader
            icon="CheckInHeartIcon"
            sx={{
              color: "accent1.900",
            }}
          />
        </Stack>
      </Stack>

      <Stack pb={2} spacing={2} px={2}>
        {children}
      </Stack>
    </Stack>
  );
}
