import { Button, Stack, Typography } from "@mui/material";
import WatermarkIcon from "../WatermarkIcon";
import theme from "../../theme";
import FullscreenBackground from "../FullscreenBackground";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { getConfigurationVariable } from "../../services/configuration-adapter";
import { useEffect, useState } from "react";
import useGetProductSelfServiceURL from "../../hooks/useGetProductSelfServiceURL";
import ButtonWithLoadingState from "../ButtonWithLoadingState";
import CircledBackButton from "../CircledBackButton";
import MaxWidthContainer from "../MaxWidthContainer";

export default function MarketplaceSelfServiceConfirmation() {
  const { t } = useTranslation();
  const { productID } = useParams();

  const urlFallback = getConfigurationVariable(
    "VITE_APP_MARKETPLACE_PRODUCT_SELF_SERVICE_SIGN_UP_URI"
  );
  const [selfServiceURL, setSelfServiceURL] = useState("");
  const { url, isError } = useGetProductSelfServiceURL({
    productID: productID || "",
  });

  useEffect(() => {
    if (isError || url !== null) {
      setSelfServiceURL(url || urlFallback);
    }
  }, [url, isError]);

  return (
    <MaxWidthContainer>
      <Stack sx={{ height: "100vh" }} p={2} justifyContent="space-between">
        <CircledBackButton showLabel={true} labelColor="primary.contrastText" />
        <Stack
          justifyContent="flex-end"
          color="primary.contrastText"
          sx={{
            height: "100%",
            p: 2,
          }}
        >
          <Stack textAlign="center" spacing={4} alignItems="center">
            <WatermarkIcon
              height={128}
              width={128}
              iconSize="medium"
              color={theme.palette.primary[300]}
              foreground={theme.palette.neutral.contrastText}
              Icon={"FaceScanBlue"}
            />
            <Stack textAlign="center" spacing={2}>
              <Typography variant="h1" color="inherit">
                {t("MarketplaceSelfServiceConfirmation.title")}
              </Typography>
              <Typography variant="h3" color="inherit">
                {t("MarketplaceSelfServiceConfirmation.subtitle")}
              </Typography>
              <Typography variant="body1" color="inherit">
                {t("MarketplaceSelfServiceConfirmation.content")}
              </Typography>

              <Stack spacing={2}>
                {!selfServiceURL ? (
                  <ButtonWithLoadingState color="info"></ButtonWithLoadingState>
                ) : (
                  <Button
                    color="info"
                    component="a"
                    href={selfServiceURL}
                    target="_blank"
                  >
                    {t("common.continueButton")}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Stack>
          <FullscreenBackground color="primary.main" />
        </Stack>
      </Stack>
    </MaxWidthContainer>
  );
}
