import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Divider,
  IconButton,
  Stack,
  Typography,
  ListItem,
} from "@mui/material";
import {
  IdentityDocumentType,
  Member,
  MembershipStatus,
} from "../../services/core-api-adapter";
import FaceIconBrand from "../../theme/icons/FaceIconBrand";
import MembershipDependentsStatusModal from "../MembershipDependentsStatusModal";
import IconLoader from "../IconLoader";
import { replaceSubjectWithString } from "../../utils";

interface MembershipManagementDependentsListProps {
  dependents: Member[];
  onEdit: (dependent: Member) => void;
  onRemove: (dependent: Member) => void;
}

export default function MembershipManagementDependentsList({
  dependents,
  onEdit,
  onRemove,
}: MembershipManagementDependentsListProps) {
  const { t } = useTranslation();
  const [
    isMembershipDependentsStatusModalOpen,
    setIsMembershipDependentsStatusModalOpen,
  ] = useState(false);

  return (
    <>
      <Stack spacing={1}>
        <MembershipDependentsStatusModal
          isOpen={isMembershipDependentsStatusModalOpen}
          onClose={() => setIsMembershipDependentsStatusModalOpen(false)}
          status={MembershipStatus.PENDING}
        />

        <Typography variant="h3" fontWeight={600}>
          {t("MembershipManagementDependentsList.dependentsList.label")}
        </Typography>

        <Stack
          component="ul"
          divider={<Divider />}
          aria-label={t(
            "MembershipManagementDependentsList.dependentsList.label"
          )}
        >
          {dependents.map((dependent, index) => {
            const fullName = dependent.memberDetails.memberFullName;
            const key = `${fullName}_${index}`;
            const identityDocumentValue =
              dependent?.memberDetails?.identityDocumentValue || "";
            const identityDocumentType = dependent.memberDetails
              .identityDocumentType
              ? replaceSubjectWithString(
                  dependent.memberDetails.identityDocumentType,
                  IdentityDocumentType.UNUID,
                  "UNUID"
                )
              : null;

            return (
              <ListItem key={key} aria-label={fullName} sx={{ px: 0, py: 2 }}>
                <Stack spacing={2} direction="row" width="100%">
                  <FaceIconBrand
                    sx={{ fontSize: (theme) => theme.spacing(4) }}
                  />

                  <Stack spacing={2} width="100%">
                    <Stack spacing={1}>
                      <Stack
                        spacing={1}
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="h4"
                          fontWeight="600"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {fullName}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack
                      direction="row"
                      spacing={1}
                      width="100%"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Stack>
                        {identityDocumentType && (
                          <Typography variant="body2" color="GrayText">
                            {t(
                              `MembershipDependentsDetailPanel.dependentsList.item.${identityDocumentType}Label` as any
                            )}
                          </Typography>
                        )}
                        {identityDocumentValue && (
                          <Typography sx={{ wordBreak: "break-word" }}>
                            {dependent.memberDetails.identityDocumentValue}
                          </Typography>
                        )}
                      </Stack>

                      <Stack direction="row" spacing={1} alignItems="flex-end">
                        <IconButton
                          onClick={() => onEdit(dependent)}
                          color="primary"
                          aria-label={t("common.editButton")}
                        >
                          <IconLoader icon="PenIcon" />
                        </IconButton>

                        <IconButton
                          onClick={() => onRemove(dependent)}
                          color="primary"
                          aria-label={t("common.removeButton")}
                        >
                          <IconLoader icon="BinIcon" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </ListItem>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
}
