import { useEffect } from "react";
import AuthFlow from "../../components/AuthFlow";
import { trackPageViewV2 } from "../../services/analytics-adapter";

export default function AuthPage() {
  useEffect(() => {
    trackPageViewV2({
      pageName: "Auth:Auth flow",
      pageSubSection1: "Auth",
      pageSubSection2: "Auth:Auth flow",
      pageCategory: "Auth",
    });
  }, []);
  return <AuthFlow />;
}
